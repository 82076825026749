import React, { Component, FC } from "react";
import { URL_DATA } from "customGlobal";
import { FilePond } from "react-filepond";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import DragNDropContext from "composants/DragNDropContext";

import { getUIContext } from "api/common";
import { t } from "utils/i18n";

import "filepond/dist/filepond.min.css";
import auth, { updateToken } from "auth";

interface FileUploadProps {
  tableName: string;
  sjmoCode?: string;
  contextId?: string;
  baseURL?: string;
  customRoute?: string;
  onLoad?(fileId: string): void;
  onFileUploadComplete?(file: any): void;
}

interface FileUploadState {
  documentsToAdd: File[];
}

class FileUploadInternal extends Component<FileUploadProps, FileUploadState> {
  state = {
    documentsToAdd: []
  };
  render() {
    const serverProps = {
      url: this.props.baseURL ? this.props.baseURL : URL_DATA() + "/document",
      process: (
        fieldName: any,
        file: any,
        metadata: any,
        load: any,
        error: any,
        progress: any,
        abort: any
      ) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        const formData = new FormData();
        formData.append(fieldName, file, file.name);

        const request = new XMLHttpRequest();
        const urlParams = getUIContext({
          sjmoCode: this.props.sjmoCode
        });

        const baseURL = this.props.baseURL ?? URL_DATA() + "/document";

        const route =
          this.props.customRoute ??
          `/uploadFile?tableName=${this.props.tableName}&id=${encodeURIComponent(
            this.props.contextId as string
          )}&${urlParams}`;

        request.open("POST", `${baseURL}${route}`);
        request.setRequestHeader("Authorization", "Bearer " + auth.token);

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = e => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        const onLoadForParent = this.props.onLoad;
        request.onload = function() {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
            onLoadForParent?.(request.responseText);
          } else {
            // Can call the error method if something is wrong, should exit after
            error("oh no");
          }
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          }
        };
      }
    };

    return (
      <FilePond
        allowMultiple={true}
        server={serverProps}
        name="file"
        allowRevert={false}
        instantUpload={true}
        labelIdle={t("commun_drag_clic_upload")}
        labelFileProcessing={t("commun_enregistrement_en_cours")}
        labelFileProcessingComplete={t("commun_fichier_enregistre")}
        labelFileProcessingError={t("commun_erreur_enregistrement")}
        labelTapToRetry={t("commun_cliquez_pour_reesayer")}
        onprocessfile={this.props.onFileUploadComplete}
        beforeAddFile={() => updateToken().then(() => true)}
      />
    );
  }
}

function Filedrop(props: FileUploadProps) {
  const [, drop] = useDrop(() => ({
    accept: NativeTypes.FILE
  }));

  return (
    <div ref={drop}>
      <FileUploadInternal {...props} />
    </div>
  );
}

const FileUpload: FC<FileUploadProps> = props => {
  return (
    <DragNDropContext>
      <Filedrop {...props} />
    </DragNDropContext>
  );
};

export default FileUpload;
