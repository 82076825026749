import React, { FC, useState, useEffect, useCallback } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import { fetchMailData, fetchMessageThread } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import { t } from "utils/i18n";
import Scrollbars from "react-custom-scrollbars";
import { Mail } from "./MailList";
import TabHeader from "./TabHeader";
import { TEXT_FORMAT } from "../RichTextEditor/TextEditor";
import { useInfiniteQuerySatellite } from "hooks/useInfiniteQuerySatellite";
import { MAILS } from "constant/satellite";
import { Trans } from "react-i18next";
import { Control } from "composants/form";
import DocumentViewer, { DocumentLightDto } from "./DocumentViewer";
import InfiniteList from "containers/listegenerique/InfiniteList";
import Modal from "composants/Modal/Modal";

const TabHistoriqueMail: FC<TabSatelliteProps> = props => {
  const { contextId, tableName, countAction } = props;
  const loadMoreButtonRef = React.useRef<HTMLDivElement | null>(null);

  const [displayMailAttachment, setDisplayMailAttachment] = useState<DocumentLightDto | null>(null);
  const [thread, setThread] = useState<Mail[]>([]);
  const [showThread, setShowThread] = useState<boolean>(false);

  const {
    data: mails,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuerySatellite<Mail>(MAILS, tableName, contextId, fetchMailData);

  useEffect(() => {
    if (contextId) {
      countAction(tableName, contextId);
    }
  }, [contextId, countAction, tableName]);

  const buildThread = useCallback(
    (root: Mail) => {
      fetchMessageThread(props.tableName, root.parentId as number)
        .then(response => {
          const thread: Mail[] = response.data;

          let copy: Mail = Object.assign({}, root);
          copy.parentId = undefined;
          thread.unshift(copy);

          setThread(response.data);
          setShowThread(true);
        })
        .catch(e => {
          const er = e as AxiosError<any>;
          if (!er.response) {
            return;
          }

          const message: Message = {
            code: er.response.data.code,
            message: t(er.response.data.message),
            type: er.response.data.type,
            target: er.response.data.target
          };
          addMessage(message);
        });
    },
    [props.tableName]
  );

  return (
    <>
      <TabHeader
        i18nKey="commun_mails_liees"
        count={props.count}
        tableName={props.tableName}
        contextId={props.contextId}
        sjmoCode={props.sjmoCode}
      />
      <Scrollbars autoHide style={{ height: props.height }}>
        <InfiniteList
          data={mails?.pages}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          loadMoreButtonRef={loadMoreButtonRef}
          fetchNextPage={fetchNextPage}
          renderCell={pojo => (
            <BuildOneMessage
              key={pojo.id}
              mail={pojo as any}
              textType={TEXT_FORMAT}
              buildThread={buildThread}
              setDisplayMailAttachment={setDisplayMailAttachment}
            />
          )}
          listHeight={props.height as number}
        />
      </Scrollbars>
      {displayMailAttachment && (
        <DocumentViewer
          document={displayMailAttachment}
          onClose={() => setDisplayMailAttachment(null)}
        />
      )}
      {showThread && (
        <Modal
          title={t("commun_conversation")}
          show
          height="70vh"
          hideFooter={true}
          onClose={() => {
            setShowThread(false);
          }}
        >
          {thread.map(m => (
            <BuildOneMessage
              mail={m}
              textType={TEXT_FORMAT}
              buildThread={buildThread}
              setDisplayMailAttachment={setDisplayMailAttachment}
            />
          ))}
        </Modal>
      )}
    </>
  );
};

function BuildOneMessage({
  mail,
  textType,
  buildThread,
  setDisplayMailAttachment
}: {
  mail: Mail;
  textType: string;
  buildThread(mail: Mail): void;
  setDisplayMailAttachment(d: DocumentLightDto): void;
}) {
  const displayText =
    textType === TEXT_FORMAT ? (
      <div dangerouslySetInnerHTML={{ __html: mail.texte }} />
    ) : (
      <pre className="is-paddingless tab-message-pre">{mail.texte}</pre>
    );

  const pjs = (
    <Control className="my-6">
      <div className="tags">
        {mail.attachments
          ? mail.attachments.map(doc => (
              <a
                key={doc.path}
                className="tag is-link mx-8"
                onClick={() => {
                  setDisplayMailAttachment(doc);
                }}
              >
                {doc.name}
              </a>
            ))
          : []}
      </div>
    </Control>
  );

  return (
    <>
      <div className="card donnees-satellite-container" key={mail.id} style={{ margin: 10 }}>
        <header className="card-header">
          <p className="card-header-title">{mail.object}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="media">
              <div className="media-content">
                <div className="message-deale">
                  {displayText}
                  {pjs}
                  <div className="has-text-weight-light">
                    <Trans i18nKey="commun_mail_envoye_de">
                      Message envoyé de {{ issuerName: mail.emmetteurPrenom }}
                      {{ issuerLastName: mail.emmetteurNom }} à
                      {{ receiverName: mail.destinatairePrenom }}
                      {{ receiverLastName: mail.destinataireNom }}, le
                      {{ date: mail.date, format: "date" }}.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="card-footer">
          <>
            {mail.parentId && (
              <a
                className="card-footer-item"
                onClick={() => {
                  buildThread(mail);
                }}
              >
                {t("commun_voir_conversation")}
              </a>
            )}
          </>
        </footer>
      </div>
    </>
  );
}

export default TabHistoriqueMail;
