import React, { useMemo, useState } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";

import Scrollbars from "react-custom-scrollbars";
import { t } from "utils/i18n";
import { ButtonLink, Button } from "@axin-org/comet";

import "./satelliteTab.css";
import { createMany, fetchConfigurateurData, preRecord } from "api";

import ProcessusMenu from "../processus/ProcessusMenu";
import TabHeader from "./TabHeader";
import { Row, Col } from "../Layout";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { Fa } from "composants/Icon";

import snakeCase from "lodash-es/snakeCase";
import { useInfiniteQuerySatellite } from "hooks/useInfiniteQuerySatellite";
import { CONFIGURATEURS } from "constant/satellite";
import InfiniteList from "containers/listegenerique/InfiniteList";

export interface Configuration {
  id: number;
  titre: string;
  ovPersoResultatId: string;
  status: string;
  dateGeneration: string;
  articleCode: string;
  articleDesignation: string;
  articleId: string; // utiliser pour la navigation
  clientCode: string;
  clientDesignation: string;
  userName: string;
  userLastName: string;
}

function TabConfigurateur({
  sjmoCode,
  tableName,
  contextId,
  count,
  height,
  countAction
}: TabSatelliteProps) {
  const [isCreatingNewConfig, setIsCreatingNewConfig] = useState(false);
  const loadMoreButtonRef = React.useRef<HTMLDivElement | null>(null);

  const {
    data: configurations,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useInfiniteQuerySatellite(CONFIGURATEURS, tableName, contextId, fetchConfigurateurData);

  async function createConfiguration() {
    setIsCreatingNewConfig(true);
    try {
      const sysTableId = snakeCase(tableName).toUpperCase();

      const entity = await preRecord({
        sjmoCode,
        tableName: "cfgPersoLien",
        context: { sysTableId: sysTableId, cfplEntityId: contextId as string }
      }).then(
        res => res.data,
        err => {
          console.error("error during prerecord of cfgPersoLien");
          return null;
        }
      );

      if (entity == null) return;

      await createMany("cfgPersoLien", [entity], sjmoCode);

      refetch();
      countAction(tableName, contextId);
    } finally {
      setIsCreatingNewConfig(false);
    }
  }

  const mappedConf = useMemo(() => {
    const pages: { data: any[]; meta: any }[] = [];
    configurations?.pages.forEach(p => {
      const pairs = [];
      let current = { left: undefined, right: undefined };
      const base = p.data;
      for (let i = 0; i < base.length; i++) {
        if (i % 2 === 0) {
          current = { left: base[i], right: undefined };
        } else {
          current.right = base[i];
          pairs.push(current as any);
        }

        if (i % 2 === 0 && i === base.length - 1) {
          pairs.push(current as any);
        }
      }
      pages.push({ data: pairs, meta: {} });
    });
    return pages;
  }, [configurations?.pages]);

  return (
    <>
      <TabHeader
        i18nKey="commun_configurateurs_liees"
        count={count}
        tableName={tableName}
        contextId={contextId}
        sjmoCode={sjmoCode}
      >
        <Button onClick={createConfiguration}>
          <span className="icon">
            {isCreatingNewConfig ? <Fa icon="spinner" spin /> : <Fa icon="plus" />}
          </span>
          <span>{t("commun_creer")}</span>
        </Button>
      </TabHeader>
      <Scrollbars autoHide style={{ height: height }}>
        <InfiniteList
          data={mappedConf.length > 0 ? mappedConf : undefined}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          loadMoreButtonRef={loadMoreButtonRef}
          fetchNextPage={fetchNextPage}
          renderCell={pojo => (
            <CarConfigurateurList
              key={pojo.left.id}
              sjmoCode={sjmoCode}
              configuration={pojo as any}
            />
          )}
          listHeight={height as number}
        />
      </Scrollbars>
    </>
  );
}

function CarConfigurateurList({
  sjmoCode,
  configuration
}: {
  sjmoCode: string;
  configuration: { left: Configuration; right: Configuration };
}) {
  return (
    <Row style={{ width: "100%" }}>
      <Col span={6}>
        <CardConfigurateur
          key={configuration.left.id}
          sjmoCode={sjmoCode}
          config={configuration.left}
        />
      </Col>

      <Col span={6}>
        {configuration.right && (
          <CardConfigurateur
            key={configuration.right.id}
            sjmoCode={sjmoCode}
            config={configuration.right}
          />
        )}
      </Col>
    </Row>
  );
}

function CardConfigurateur({ sjmoCode, config }: { sjmoCode: string; config: Configuration }) {
  const url = `${import.meta.env.VITE_CONFIGURATEUR_BASE_URL}${config.ovPersoResultatId}`;

  return (
    <div className="box donnees-satellite-container" key={config.id}>
      <article className="media">
        <div className="media-content">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <span className="subtitle is-5">{config.titre}</span>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <span className={`tag ${config.status === "GEN" ? "is-success" : "is-warning"}`}>
                  {config.status}
                </span>
              </div>
              <div className="level-item">
                <ButtonLink className="is-size-4">
                  <a href={url} target="_blank">
                    <span className="icon">
                      <Fa icon="external-link" />
                    </span>
                  </a>
                </ButtonLink>
              </div>
            </div>
          </nav>

          <div>
            <span>
              {`${t("commun_par")} : 
              ${config.userName !== null ? config.userName : ""} 
              ${config.userLastName !== null ? config.userLastName : ""}
             ${config.dateGeneration ? ", " + t("commun_le") + " : " + config.dateGeneration : ""}
             ${
               config.clientDesignation !== null
                 ? `, ${t("commun_pour")} : ${config.clientDesignation} (${config.clientCode})`
                 : ""
             }`}
            </span>
          </div>

          <h2 className="is-size-5">
            {config.articleCode && config.articleCode !== null ? (
              <>
                <div>{`${t("commun_article_genere")} : `}</div>
                <ProcessusProvider
                  sjmoCode={sjmoCode}
                  tableName="article"
                  selected={config.articleId}
                >
                  <ProcessusMenu isAnchor isRight={true} color="link">
                    {`${config.articleDesignation} (${config.articleCode})`}
                  </ProcessusMenu>
                </ProcessusProvider>
              </>
            ) : (
              <div style={{ marginBottom: "1.85rem" }}>{t("commun_aucun_article_genere")}</div>
            )}
          </h2>
        </div>
      </article>
    </div>
  );
}

export default TabConfigurateur;
