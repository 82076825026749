import { assign, DoneInvokeEvent, createMachine, StateFrom } from "xstate";
import { Pojo, WhenValidateItemResult } from "types/Galaxy";
import produce from "immer";
import { track } from "tracking";
import toaster from "composants/toaster/toaster";
import { validatePojo } from "utils/entities.utils";
import { createMany } from "api";
import { t } from "utils/i18n";

export interface FormMachineStates {
  states: {
    idle: {};
    validating: {
      states: {
        started: {};
        slow: {};
        stuck: {};
      };
    };
    saving: {
      states: {
        started: {};
        slow: {};
        stuck: {};
      };
    };
  };
}

export interface FormMachineContext {
  waitingSave: boolean;
  entity: Pojo | null;
  isDirty: boolean;
  changedFields: Record<string, boolean>;
  result: Record<
    string,
    {
      code: string;
      message: string;
    }
  >;
}

export type FormMachineEvents =
  | { type: "LOAD_ENTITY"; sjmoCode: string; entity: Pojo | null }
  | { type: "CHANGE"; sjmoCode: string; field: string; compo: string; value: any; wvi: boolean }
  | { type: "SAVE"; sjmoCode: string }
  | { type: "BLUR"; sjmoCode: string; field: string; wvi: boolean };

// Certains WVI doivent être exécutés directement pour éviter d'avoir des erreurs
// visuelles ou incohérences pour l'utilisateur.
// En effet, pour les composants de type checkbox ou select, l'utilisateur n'a pas l'impression
// d'avoir le focus sur l'élément. Et lorsque la valeur change, même si le focus est encore sur l'élément, la
// valeur saisie est considéré comme finale (pas de modification à apporter comme ce n'est pas de la saisi libre)
const INSTANT_WVI_TYPES = ["SD", "GS", "CH", "GSV", "GSVL"];

const formMachine = createMachine<FormMachineContext, FormMachineEvents>(
  {
    schema: {
      context: {} as FormMachineContext,
      events: {} as FormMachineEvents
    },
    id: "form-machine",
    initial: "idle",
    context: {
      waitingSave: false,
      entity: null,
      isDirty: false,
      changedFields: {},
      result: {}
    },
    states: {
      idle: {
        always: [{ target: "saving", cond: ctx => ctx.waitingSave === true }],
        on: {
          LOAD_ENTITY: {
            internal: true,
            actions: assign({
              entity: (ctx, event) => event.entity,
              isDirty: (ctx, event) => false,
              changedFields: () => ({}),
              result: () => ({})
            })
          },
          CHANGE: [
            {
              cond: (context, event) =>
                context.entity != null &&
                event.wvi === true &&
                INSTANT_WVI_TYPES.includes(event.compo),
              target: "validating",
              actions: ["modifyPojo", "ext:modifyPojo"]
            },
            {
              internal: true,
              actions: ["modifyPojo", "ext:modifyPojo", "fieldChanged"]
            }
          ],
          SAVE: "saving",
          BLUR: {
            cond: (ctx, event) =>
              ctx.entity != null && event.wvi === true && ctx.changedFields[event.field] === true,
            target: "validating"
          }
        }
      },
      validating: {
        invoke: {
          id: "validateField",
          src: "validate",
          onDone: {
            target: "idle",
            actions: [
              assign({
                entity: (context, event) => {
                  const oldStyle = context.entity?._style ?? {};
                  const newStyle = event.data.entity?._style ?? {};

                  const countStyleKeys = (newStyle && Object.keys(newStyle).length) ?? 0;
                  return {
                    ...event.data.entity,
                    _style: countStyleKeys > 0 ? newStyle : oldStyle
                  };
                },
                result: (
                  { result },
                  { data }: DoneInvokeEvent<{ field: string } & WhenValidateItemResult<Pojo>>
                ) => {
                  if (data.field && data.message) {
                    return {
                      ...result,
                      [data.field]: {
                        code: data.message.type.toLowerCase(),
                        message: data.message.message ?? ""
                      }
                    };
                  }
                  return result;
                },
                changedFields: (_, _ev) => ({})
              })
            ]
          },
          onError: {
            target: "idle",
            actions: assign({
              entity: (context, event) => {
                if (!event.data || !event.data.entity) return context.entity;

                const oldStyle = context.entity?._style ?? {};
                const newStyle = event.data.entity?._style ?? {};

                const countStyleKeys = (newStyle && Object.keys(newStyle).length) ?? 0;
                return {
                  ...event.data.entity,
                  _style: countStyleKeys > 0 ? newStyle : oldStyle
                };
              },
              result: (
                { result },
                { data }: DoneInvokeEvent<{ field: string } & WhenValidateItemResult<Pojo>>
              ) => {
                if (!data) return result;

                return produce(result, draft => {
                  draft[data.field] = {
                    message: data.message.message ?? "",
                    code: data.message.type.toLowerCase()
                  };
                });
              }
            })
          }
        },
        // on a un sous état pour gérer les loader
        // < 1s   : rien
        // 1-3 s  : on bloque avec un loader + disabled des boutons
        // > 3s   : même chose qu'avant mais on affiche un message à l'utilisateur
        initial: "started",
        states: {
          started: {
            after: {
              1000: "slow"
            }
          },
          slow: {
            after: {
              2000: "stuck"
            }
          },
          stuck: {}
        },
        on: {
          SAVE: {
            internal: true,
            actions: assign({ waitingSave: (): boolean => true })
          }
        }
      },
      saving: {
        entry: [
          () => {
            track("expert::save", "start");
          },
          assign({ waitingSave: (): boolean => false })
        ],
        invoke: {
          src: "save",
          onDone: {
            target: "idle",
            actions: [
              assign({
                isDirty: (_, __) => false,
                changedFields: (_, _event) => ({}),
                result: (_, _ev) => ({}),
                entity: (context, event: DoneInvokeEvent<Pojo>) => {
                  const oldStyle = context.entity?._style ?? {};
                  const newStyle = event.data._style ?? {};

                  const countStyleKeys = (newStyle && Object.keys(newStyle).length) ?? 0;
                  return {
                    ...event.data,
                    _style: countStyleKeys > 0 ? newStyle : oldStyle
                  };
                }
              }),
              () => {
                track("expert::save", "success");
              },
              "saveNotification",
              "saveCallback"
            ]
          },
          onError: {
            target: "idle",
            actions: () => {
              track("expert::save", "error");
            }
          }
        },
        // on a un sous état pour gérer les loader
        // < 1s   : rien
        // 1-3 s  : on bloque avec un loader + disabled des boutons
        // > 3s   : même chose qu'avant mais on affiche un message à l'utilisateur
        initial: "started",
        states: {
          started: {
            after: {
              1000: "slow"
            }
          },
          slow: {
            after: {
              2000: "stuck"
            }
          },
          stuck: {}
        }
      }
    }
  },
  {
    services: {
      // service qui lance le WVI
      validate: (context, event: FormMachineEvents) => {
        return new Promise<Record<string, any> | void>((resolve, reject) => {
          if (context.entity != null && (event.type === "CHANGE" || event.type === "BLUR")) {
            validatePojo(
              event.sjmoCode,
              context.entity.tableName,
              event.field,
              context.entity,
              success => {
                resolve({ ...success.data, field: event.field });
              },
              data => {
                reject({ ...data, field: event.field });
              }
            );
          } else {
            resolve();
          }
        });
      },
      save: async (context, event: FormMachineEvents) => {
        if (context.entity == null) return null;

        const res = await createMany(
          context.entity.tableName,
          [context.entity],
          event.sjmoCode,
          true
        );
        return res.data[0];
      }
    },
    actions: {
      modifyPojo: assign({
        entity: (context, event) => {
          if (context.entity == null) return context.entity;
          return produce(context.entity, draft => {
            if (event.type === "CHANGE") {
              draft[event.field] = event.value;
            }
          });
        },
        isDirty: (ctx, e) => true
      }),
      fieldChanged: assign({
        changedFields: (context, event) => {
          return produce(context.changedFields, draft => {
            if (event.type === "CHANGE") {
              draft[event.field] = true;
            }
          });
        }
      }),
      saveNotification: () => {
        toaster.success(t("commun_modification_enregistrees"));
      }
    }
  }
);

export function isFormLoadingState(state: StateFrom<typeof formMachine>) {
  return (
    state.matches({ validating: "slow" }) ||
    state.matches({ validating: "stuck" }) ||
    state.matches("saving")
  );
}

export default formMachine;
