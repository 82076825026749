import React, {
  FC,
  useReducer,
  useMemo,
  useState,
  useEffect,
  useRef,
  SyntheticEvent,
  useCallback,
  useContext,
  Dispatch,
  useLayoutEffect,
  MouseEvent,
  KeyboardEvent
} from "react";
import classNames from "classnames";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { RSQLCriteria, Operators, RSQLFilterExpression } from "rsql-criteria-typescript";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import get from "lodash-es/get";
import set from "lodash-es/set";
import isEqual from "lodash-es/isEqual";

import { getConfiguredCache } from "cache";

import reducer, {
  initialStateDatatable,
  DatatableState,
  AllDatatableActions,
  parseSortStr
} from "./datatableReducer";
import Table, { DatatableSort, BreakRow } from "composants/datatable/Table";
import { TableColumn, CustomRenderProperties } from "composants/datatable/TableColumn";
import { SimpleComponent, TypeSimpleComponent } from "enum";
import { Pojo, WhenValidateItemResult } from "types/Galaxy";
import useInteractions, { DataInteractionContext } from "hooks/useInteractions";
import {
  mapToRSQL,
  initRsqlCriteria,
  GSBuilder,
  GS,
  convertTermByOperator
} from "utils/query.utils";
import {
  DATATABLE_EXPAND,
  DATATABLE_SELECTION,
  DATATABLE_ACTION
} from "composants/datatable/staticComponent";
import { Fa } from "composants/Icon";
import {
  InputFilter,
  AutocompleteFilter,
  CheckboxFilter,
  SelectFilter,
  OelFilter,
  FilterProps,
  CalendarFilter,
  CalendarHourFilter
} from "composants/datatable/TableFilter";
import { ComponentState, ComponentReadOnly } from "types/Component";
import { SortableHeaderItem } from "composants/datatable/SortableElements";
import { Menu } from "composants/DropDown/Menu";
import SatelliteMenu from "composants/satellite/SatelliteMenu";

import * as api from "api/datatable";
import { DatatableFocusState } from "types/Focus";
import { ErrorBoundary } from "composants/ErrorBoundary";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";

import { DragAndDropBlockContext } from "composants/DragNDropContext";

import Toolbar, { ToolbarButtonOverride } from "composants/datatable/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import { Field } from "composants/form";
import { convertValue, checkEntityValid } from "utils/entities.utils";

import Axios, { AxiosPromise, CancelTokenSource } from "axios";
import { URL_DOWNLOAD } from "customGlobal";
import FilterBarComponent from "composants/datatable/FilterBarComponent";

import BreakRowDialog from "composants/datatable/dialog/BreakRowDialog";
import { Omit } from "types/utils";
import Input, { InputMask, InputNumber } from "composants/input/Input";
import Autocomplete, { AutocompleteProps } from "composants/autocomplete/AutoComplete";
import { getCacheKey, updateAutocompleteCache } from "composants/autocomplete/autocomplete-cache";
import Calendar from "composants/calendar/Calendar";
import Checkbox from "composants/checkbox/Checkbox";
import CalendarHours from "composants/calendar/CalendarHours";
import { DatatableEditor } from "composants/RichTextEditor/TextEditor";
import Select from "composants/select/Select";
import Oel from "composants/oel/Oel";
import {
  getReadonlyValue,
  getDimensionFromEvent,
  validateForm,
  getOptionsByType
} from "utils/component.utils";
import { uuidv4 } from "utils/uuid.utils";
import { addMessage } from "actions/messages";
import { showContextMenu } from "actions/contextMenu";

import { existList } from "api";
import { LoadableLoader } from "composants/Loader";
import { arrayMove } from "react-sortable-hoc";
import { contextualize } from "actions/contextTreeModule";
import { GalaxieListenerCallbackContext } from "containers/galaxy/GalaxieContextListener";
import toaster from "composants/toaster/toaster";
import { format } from "date-fns";
import { ButtonNoStyle } from "composants/button";
import { useDirtyGalaxy } from "layout/Dirty";
import { NotificationGroup } from "types/Notification";
import { DraggableListItem } from "constant/draggableGroupList";
import SelectFieldsModal from "containers/SelectFieldsModal/SelectFieldsModal";

import DragNDropContext from "composants/DragNDropContext";
import { Message } from "types/Message";
import { track } from "tracking";
import auth from "auth";
import { useRegisterProcessus } from "features/processus/processusManager";
import SatelliteMenuButton from "composants/satellite/SatelliteMenuButton";
import { getProcessusDefinitionByCompositeId } from "api/processus";
import { ProcessusDefinition } from "types/Processus";
import AutocompleteView from "composants/autocompleteView/autocompleteView";
import AutocompleteViewFree from "composants/autocompleteViewFree/autocompleteViewFree";

const GenericMarkdownDisplay = React.lazy(() =>
  import("composants/genericDisplay/GenericMarkdownDisplay")
);

interface DatatableProps {
  sjmoCode: string;
  ctrlkey: string;
  tableName: string;
  selectionActive?: boolean;
  showActionColumns?: boolean;
  // currentMainEntityId?: string | null;
  clearCacheOnClose?: boolean;
  hidden?: boolean;
  toolbarButtonVisibility?: ToolbarButtonOverride;
  additionnalClause?: RSQLCriteria;
  nodeType?: string;
  processIdDrop?: string;
  readOnly?: boolean;
  onRowClick?: (id: number | string | null) => void;
  onAfterSaveDatatable?(): void;
  onAfterDeleteDatatable?(): void;
  onAfterProcessListeGeneric?(): void;
}

type DatatableAllProps = DatatableProps & {
  interactions: Record<string, any>;
  initialState: DatatableState;
  callbackRefresh?(callback: () => void): void;
};

type ExportType = "XLS" | "PDF";

function withDatableDragNDrop(Comp: React.ComponentType<Omit<DatatableAllProps, "initialState">>) {
  const Wrapper: FC<DatatableProps> = props => {
    const [_, { register: registerProcessus }] = useRegisterProcessus();
    const { getDirty } = useDirtyGalaxy();
    const [t] = useTranslation();
    const [process, setProcess] = useState<ProcessusDefinition | null>(null);
    const processLabel = process?.label;

    useEffect(() => {
      if (props.processIdDrop) {
        getProcessusDefinitionByCompositeId(props.sjmoCode, props.processIdDrop).then(response => {
          setProcess(response.data);
        });
      }
    }, [props.processIdDrop, props.sjmoCode]);

    const blockContext = useContext(DragAndDropBlockContext);
    const interactions = useInteractions({
      sjmoCode: props.sjmoCode,
      ctrlKey: props.ctrlkey,
      tableName: props.tableName
    });
    const dtCallbackRef = useRef<Function | null>(null);

    const callbackRefresh = useCallback((callback: () => void) => {
      dtCallbackRef.current = callback;
    }, []);

    const canDrop = props.nodeType ? !blockContext.locked[props.nodeType] : false;

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: props.nodeType || "unknown",
        canDrop() {
          return canDrop;
        },
        drop(_, monitor) {
          if (monitor.canDrop()) {
            onDrop(monitor.getItem() as any);
          }
        },
        collect: monitor => ({
          isOver: monitor.isOver()
        })
      }),
      [process, interactions]
    );

    function onDrop(draggableListItem: DraggableListItem) {
      let toLaunch: Pojo[];
      if (getDirty(props.sjmoCode, props.ctrlkey)) {
        toaster.notify({
          id: uuidv4(),
          group: NotificationGroup.DEFAULT,
          title: t("commun_pas_drop_quand_modification_pas_enregistrer"),
          priority: "NORMAL",
          intent: "DANGER",
          createdAt: format(Date.now())
        });
        return;
      }
      if (Array.isArray(draggableListItem.items)) {
        toLaunch = draggableListItem.items.map((pojo: Pojo) => {
          const pojoWithoutLabel = { ...pojo, label: "", tooltip: "" };
          const pojoAndInteractions: Pojo = { ...pojoWithoutLabel, ...interactions };
          return pojoAndInteractions;
        });
      } else {
        const pojoWithoutLabel = { ...draggableListItem, label: "", tooltip: "" } as Pojo;
        toLaunch = [{ ...pojoWithoutLabel, ...interactions }];
      }

      blockContext.lockDragForNodeType(props.nodeType as string);

      if (props.processIdDrop) {
        track("datatable::drop", { processId: props.processIdDrop });
        if (process && process.isOneByOne && process.needEntity) {
          for (let index = 0; index < toLaunch.length; index++) {
            const element = toLaunch[index];
            registerProcessus(
              {
                module: props.sjmoCode,
                type: "traitement",
                compositeID: props.processIdDrop,
                selected: [element],
                label: processLabel ?? ""
              },
              ctx => {
                dtCallbackRef.current && dtCallbackRef.current();
                if (props.onAfterProcessListeGeneric) {
                  props.onAfterProcessListeGeneric();
                }
                blockContext.unlockDragForNodeType(props.nodeType as string);
              }
            );
          }
        } else {
          registerProcessus(
            {
              module: props.sjmoCode,
              type: "traitement",
              compositeID: props.processIdDrop,
              selected: process?.needEntity ? toLaunch : [],
              label: processLabel ?? ""
            },
            ctx => {
              dtCallbackRef.current && dtCallbackRef.current();
              if (props.onAfterProcessListeGeneric) {
                props.onAfterProcessListeGeneric();
              }
              blockContext.unlockDragForNodeType(props.nodeType as string);
            }
          );
        }
      }
    }

    return (
      <div
        ref={props.nodeType ? drop : undefined}
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        <Comp {...props} interactions={interactions} callbackRefresh={callbackRefresh} />
        {isOver && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              boxShadow: "hsl(217, 60%, 80%) 0px 0px 6px 1px inset"
            }}
          />
        )}
      </div>
    );
  };

  const displayName = Comp.displayName || Comp.name;
  Wrapper.displayName = `Dnd(${displayName})`;

  const DirectDatatable: FC<DatatableProps> = props => {
    const interactions = useInteractions({
      sjmoCode: props.sjmoCode,
      ctrlKey: props.ctrlkey,
      tableName: props.tableName
    });
    return <Comp {...props} interactions={interactions} />;
  };
  DirectDatatable.displayName = `NoDnd(${displayName})`;

  const ParentWrapper: FC<DatatableProps> = props => {
    return props.nodeType ? <Wrapper {...props} /> : <DirectDatatable {...props} />;
  };

  ParentWrapper.displayName = `withDatableDragNDrop(${displayName})`;

  return ParentWrapper;
}

const TAB_ID = uuidv4();

const DATATABLE_CACHE = getConfiguredCache({
  version: 1,
  maxAge: Infinity,
  name: "datatable-cache"
});

export function clearCacheModule(sjmoCode: string) {
  DATATABLE_CACHE.keys().then(keys => {
    for (let key of keys) {
      if (key.startsWith(TAB_ID + ":::" + sjmoCode + "---")) {
        DATATABLE_CACHE.del(key);
      }
    }
  });
}

export function clearDatatableCache() {
  DATATABLE_CACHE.keys().then(keys => {
    for (let key of keys) {
      if (key.startsWith(TAB_ID)) {
        DATATABLE_CACHE.del(key);
      }
    }
  });
}

export const getDatatableKey = (code: string, key: string, table: string) => {
  return TAB_ID + ":::" + code + "---" + key + "---" + table;
};

function withDatatableCache(
  Comp: React.ComponentType<Omit<Omit<DatatableAllProps, "interactions">, "callbackRefresh">>
) {
  const Wrapper: FC<DatatableProps> = props => {
    const datatableKey = getDatatableKey(props.sjmoCode, props.ctrlkey, props.tableName);

    const [initialState, setInitialState] = useState<DatatableState | null>(null);

    useEffect(() => {
      DATATABLE_CACHE.get(datatableKey).then(val => {
        setInitialState(val || initialStateDatatable);
      });
    }, [datatableKey]);

    useEffect(() => {
      return () => {
        if (props.clearCacheOnClose) {
          DATATABLE_CACHE.get(datatableKey).then((def: DatatableState) => {
            if (def) {
              const newDefinition: DatatableState = {
                ...def,
                state: "RELOAD_FROM_CACHE",
                data: {
                  cache: def.data.cache,
                  entities: {},
                  newEntitiesStart: [],
                  newEntitiesLast: [],
                  totalRecords: 0
                },
                userFilter: {
                  ...def.userFilter,
                  selectedRows: []
                }
              };

              DATATABLE_CACHE.set(datatableKey, newDefinition);
            }
          });

          DATATABLE_CACHE.del(datatableKey);
        }
      };
    }, []);

    return initialState == null ? null : <Comp {...props} initialState={initialState} />;
  };
  Wrapper.displayName = "withDatatableCache(" + (Comp.displayName || Comp.name) + ")";

  return Wrapper;
}

function usePreviousFocus(newDefinition: DatatableFocusState | undefined) {
  const ref = useRef<DatatableFocusState | undefined>();
  useEffect(() => {
    ref.current = newDefinition;
  });
  return ref.current;
}

type DatatableContextType = {
  sjmoCode: string;
  tableName: string;
  ctrlKey: string;
  gridPadding: GridPaddingType;
  wviState: { [id: string]: Record<string, string> };
  readonly: ComponentReadOnly;
  onChange(entityId: string, field: string, value: any): void;
  onBlur(entityId: string, field: string): void;
  onKeyDown(rowIndex: number, columnIndex: number, e: KeyboardEvent<any>): void;
  onContextMenu(entityId: string, field: string, e: MouseEvent<any>, useView?: boolean): void;
};

const noop = () => {};
const DatatableContext = React.createContext<DatatableContextType>({
  sjmoCode: "",
  tableName: "",
  ctrlKey: "",
  gridPadding: "small",
  wviState: {},
  readonly: ComponentReadOnly.DEFAULT,
  onChange: noop,
  onBlur: noop,
  onContextMenu: noop,
  onKeyDown: noop
});

const MIN_BATCH_SIZE = 25;

const Datatable: FC<DatatableAllProps> = props => {
  const tableRef = useRef<Table | null>(null);
  const changedFieldsRef = useRef<Record<string, Record<string, boolean>>>({});
  const isApiPendingRef = useRef(false);

  const [state, dispatch] = useReducer(reducer, props.initialState);

  const totalRecords =
    state.data.totalRecords +
    state.data.newEntitiesStart.length +
    state.data.newEntitiesLast.length;

  const datatableKey = getDatatableKey(props.sjmoCode, props.ctrlkey, props.tableName);

  // Permet d'annulé 1 seul rowClick
  // Utilisé pour naviguer vers les données satellites depuis une table qui a un onRowClick
  const [grantOnRowClick, setGrantOnRowClick] = useState<boolean>(true);

  // Propriétés utilisées pour l'export PDF et XLS
  const [exportProps, setExportProps] = useState<{
    available: Pojo[];
    chosen: Pojo[];
    type: ExportType;
    showExport: boolean;
  } | null>(null);

  useEffect(() => {
    if (!props.clearCacheOnClose) {
      const cacheState: DatatableState = { ...state, state: "RELOAD_FROM_CACHE" };
      DATATABLE_CACHE.set(datatableKey, cacheState);
    }
  });

  useEffect(() => {
    function addRowWithLov(e: CustomEvent<{ pojos?: Pojo[]; pojo: Pojo }>) {
      if (e.detail.pojos) {
        dispatch({
          type: "ADD_MULTIPLE_ROW_DATATABLE_SUCCESS",
          payload: { pojos: e.detail.pojos }
        });
      } else if (e.detail.pojo) {
        dispatch({
          type: "ADD_ROW_DATATABLE",
          payload: { pojo: e.detail.pojo, position: "START" }
        });
      }
    }

    let event = `${datatableKey}--lov`;
    window.addEventListener(event, addRowWithLov);
    return () => {
      window.removeEventListener(event, addRowWithLov);
    };
  }, [datatableKey]);

  const allColumnRef = useRef<Record<string, ComponentState>>({});
  useEffect(() => {
    const def = state.definitions.columns;
    const copy = { ...allColumnRef.current };

    for (let index = 0; index < def.length; index++) {
      const element = def[index];
      copy[element.column] = element;
    }
    allColumnRef.current = copy;
  }, [state.definitions.columns]);

  useEffect(() => {
    function reloadSatelliteExists() {
      let ids: string[] = [];

      const keys = Object.keys(state.data.entities);

      for (let key of keys) {
        if (state.data.entities[key]) {
          ids.push(state.data.entities[key]);
        }
      }

      existList({ tableName: props.tableName, ids }).then(res => {
        dispatch({
          type: "DATATABLE_SATTELLITE_EXIST_SUCCESS",
          payload: { existList: res.data }
        });
      });
    }

    let event = props.ctrlkey + "--satellite";
    window.addEventListener(event, reloadSatelliteExists);
    return () => {
      window.removeEventListener(event, reloadSatelliteExists);
    };
  }, [props.ctrlkey, props.tableName, state.data.entities]);

  const reduxDispatch = useDispatch();
  const [gridPadding, setGridPadding] = useGridPadding();
  const [t] = useTranslation();

  const currentFocus = useMemo(() => {
    return state.definitions.selectedFocus !== undefined
      ? state.definitions.focus.find(focus => focus.focusId === state.definitions.selectedFocus)
      : undefined;
  }, [state.definitions.focus, state.definitions.selectedFocus]);

  const oelColumns = useMemo(() => {
    return state.definitions.columns.filter(col => col.typeCompo === "OEL").map(col => col.column);
  }, [state.definitions.columns]);

  const fetchData = useCallback(
    async function fetchData(
      startIndex: number,
      stopIndex: number,
      reset: boolean = false,
      cancelToken: CancelTokenSource
    ): Promise<Pojo[]> {
      if (!currentFocus) {
        return Promise.resolve([]);
      }
      const size = Math.max(stopIndex - startIndex, MIN_BATCH_SIZE);

      if (reset) {
        dispatch({ type: "CLEAR_DATATABLE_DATA" });
        tableRef.current?.resetInfiniteLoader();
      } else {
        dispatch({ type: "DATATABLE_TEMPORARY_LOADING", payload: { startIndex, stopIndex } });
      }

      const rsql = getRSQLFilter(
        props.interactions,
        state.userFilter.searchValues,
        state.userFilter.sortValues,
        state.breakRows.columns,
        props.additionnalClause,
        allColumnRef.current
      );

      try {
        const res = await api.findAll(
          {
            sjmoCode: props.sjmoCode,
            tableName: props.tableName,
            filter: rsql.build(),
            first: startIndex,
            size,
            includeJoinParent: true,
            includeOels: oelColumns,
            includeStyle: true,
            includeRowClassName: currentFocus.cssClass,
            contextKey: props.ctrlkey,
            filterBar: {
              ...state.userFilter.filterBar,
              filterBarDefaultDtFilter: currentFocus.defaultFilterBarDate
            }
          },
          cancelToken
        );

        let pojos: Pojo[] = [];
        let ids: string[] = [];
        // Gestion de breakLabels customs qui viennent d'une autre table
        // On ajoute alors les champs qui sont dans le breakLabel dans le pojo (on le fait descendre d'un niveau, du sous tableau au tableau principal)
        const myBreakLabels = state.definitions.focus
          .filter(focus => focus.focusId === currentFocus.focusId)
          .map(focus => focus.breakLabel)
          .flat();
        const splitBreakLabels = myBreakLabels.map(item => (item ? item.split(".") : ""));
        // Init et recupération des labels
        for (let data of res.data.data) {
          let pojo = { ...data };
          ids.push(pojo.id);
          let keys = Object.keys(pojo);
          for (let key of keys) {
            if (pojo[key] != null && typeof pojo[key] === "object" && key !== "_style") {
              // Boucler ici les splitBreakLabels
              for (let breakLabels of splitBreakLabels) {
                // myBreakLabels doit etre > 1 car sinon on a pas de split donc pas de jointure avec le sous tableau (ecris sous forme articleFamille.libelle par exemple)
                if (myBreakLabels.length === 1 && key === breakLabels[0]) {
                  if (pojo[key].hasOwnProperty(breakLabels[1])) {
                    // Ajout d'un _ au lieu du . pour que le pojo soit bien formé
                    pojo[breakLabels[0] + "_" + breakLabels[1]] = pojo[key][breakLabels[1]];
                  }
                }
              }
              const cacheKey = getCacheKey(pojo[key].tableName, pojo[key].id);
              updateAutocompleteCache(cacheKey, pojo[key]);
              pojo[key] = pojo[key].id;
            }
          }
          pojos.push(pojo);
        }
        dispatch({
          type: "FETCH_DATATABLE_DATA_SUCCESS",
          payload: {
            pojos: { meta: res.data.meta, data: pojos },
            startIndex,
            reset
          }
        });

        if (ids.length > 0) {
          existList({ tableName: props.tableName, ids }, cancelToken)
            .then(satelliteExistResponse => {
              dispatch({
                type: "DATATABLE_SATTELLITE_EXIST_SUCCESS",
                payload: { existList: satelliteExistResponse.data }
              });
            })
            .catch(() => {
              console.error("error during fetch of satellite for line of datatable");
            });
        }
        return pojos;
      } catch (e) {
        dispatch({
          type: "FETCH_DATATABLE_DATA_ERROR"
        });
        return [];
      }
    },
    [
      currentFocus,
      props.interactions,
      props.additionnalClause,
      props.sjmoCode,
      props.tableName,
      props.ctrlkey,
      state.userFilter.searchValues,
      state.userFilter.sortValues,
      state.userFilter.filterBar,
      state.breakRows.columns,
      state.definitions.columns,
      oelColumns
    ]
  );

  useEffect(() => {
    if (state.state === "EMPTY") {
      loadDatatableDefinition(props.sjmoCode, props.tableName, props.ctrlkey).then(
        ([focus, columns]) => {
          dispatch({ type: "FETCH_DEFINITIONS", payload: { focus, columns } });
        }
      );
    }
  }, [props.sjmoCode, props.ctrlkey, props.tableName, state.state]);

  const refresh = useCallback(
    function refresh() {
      track("datatable::refresh");
      dispatch({ type: "REFRESH_DATATABLE" });
      tableRef.current && tableRef.current.recomputeGrids();
      const cancelToken = Axios.CancelToken.source();
      requestTokens.current.push(cancelToken);
      return fetchData(0, MIN_BATCH_SIZE, true, cancelToken);
    },
    [fetchData]
  );

  const previousFocus = usePreviousFocus(
    state.definitions.focus.find(f => f.focusId === state.definitions.selectedFocus)
  );
  useEffect(() => {
    const selected = state.definitions.selectedFocus;
    if (selected && state.state !== "DEFINITION_OK") {
      api
        .getColumn(props.sjmoCode, selected)
        .then(res => {
          const focus = state.definitions.focus.find(
            f => f.focusId === state.definitions.selectedFocus
          );
          const isSortChanged = focus?.sort !== previousFocus?.sort;

          // Si le sort à changé on le décompose afin d'afficher visuellement le sort
          const newSort = focus?.sort ? parseSortStr(focus?.sort) : null;
          dispatch({
            type: "CHANGE_FOCUS_SUCCESS",
            payload: { columns: res.data, isSortChanged, isDirty, newSort }
          });
        })
        .catch(() => {
          console.error("cannot get the columns definitions");
        });
    }
  }, [props.sjmoCode, state.definitions.focus, state.definitions.selectedFocus]);
  // On ne met pas state.definitions.columns car ça relance le useeffect de façon inopinée

  const callback = useContext(GalaxieListenerCallbackContext);
  useEffect(() => {
    function refreshCallback() {
      refresh();
    }
    const unregister = callback(refreshCallback);
    return () => {
      unregister();
    };
  }, [callback, refresh]);

  const callbackRefresh = props.callbackRefresh;
  useEffect(() => {
    callbackRefresh && callbackRefresh(() => refresh());
  }, [callbackRefresh, refresh]);

  const saveRows = useCallback(() => {
    if (state.state !== "SAVE_PENDING") {
      track("datatable::save", { status: "started" });
      dispatch({ type: "CHANGE_SAVE_PENDING" });
      return Promise.resolve();
    }

    if (isApiPendingRef.current === true) {
      track("datatable::save", { status: "spam" });
      return Promise.resolve();
    }

    let pojos: Pojo[] = [];

    let labels: string[] = [];
    const ids = Object.keys(state.data.cache);
    for (let id of ids) {
      const current = state.data.cache[id];
      if (current.modifie === true) {
        let label = validateForm(
          current,
          state.definitions.columns,
          () => null,
          label => {
            return `${label}, ${t("commun_a_la_ligne")} : ${ids.findIndex(item => item === id) +
              1}`;
          }
        );

        let entity = state.data.cache[id];
        const isInsertable = (currentFocus?.insertable ?? false) && entity.id == null;
        const isUpdatable = (currentFocus?.updatatable ?? false) && entity.id != null;
        if (isInsertable || isUpdatable) pojos.push(entity);

        label && labels.push(label);
      }
    }
    isApiPendingRef.current = true;
    track("datatable::save", { status: "pending" });

    if (labels.length > 0) {
      toaster.notify({
        id: uuidv4(),
        group: NotificationGroup.DEFAULT,
        title: t("commun_champs_devraient_etre_remplis") + " :",
        priority: "NORMAL",
        intent: "DANGER",
        createdAt: format(Date.now()),
        message: labels.map(l => <div>{l}</div>)
      });
      dispatch({ type: "SAVE_ERROR" });
      isApiPendingRef.current = false;
      return Promise.resolve();
    }

    return api
      .createMany(props.tableName, pojos, props.sjmoCode)
      .then(() => {
        refresh();
        props.onAfterSaveDatatable && props.onAfterSaveDatatable();
      })
      .catch(e => {
        dispatch({ type: "SAVE_ERROR" });
        console.error("error during save");
        console.error(e);
      })
      .then(() => {
        isApiPendingRef.current = false;
      });
  }, [
    props.tableName,
    props.sjmoCode,
    props.onAfterSaveDatatable,
    refresh,
    state.data.cache,
    state.definitions.columns,
    state.state,
    t
  ]);

  const wvi = useCallback(
    function wvi(id: string, field: string) {
      const column = state.definitions.columns.find(col => col.column === field);
      if (column && column.wvi) {
        // on bloque toute tentative de sauvegarde.
        dispatch({ type: "CHANGE_WVI_PENDING" });

        const currentEntity = state.data.cache[id];
        if (currentEntity) {
          setTimeout(() => {
            api
              .whenValidateItem(props.sjmoCode, props.tableName, field, currentEntity, oelColumns)
              .then(res => {
                set(changedFieldsRef.current, [id], {});
                return res.data;
              })
              .catch(reason => {
                set(changedFieldsRef.current, [id, field], false);
                return reason.response.data as WhenValidateItemResult<Pojo>;
              })
              .then(wvi => {
                dispatch({ type: "WVI_DATATABLE", payload: { wvi, field } });
                if (wvi.message.message) reduxDispatch(addMessage(wvi.message));
              })
              .catch(e => {
                console.error("An error occured when handling the wvi message : ", e);
              });
          }, 0);
        }
      }
    },
    [
      oelColumns,
      props.sjmoCode,
      props.tableName,
      reduxDispatch,
      state.data.cache,
      state.definitions.columns
    ]
  );

  /**
   * on utilise useLayoutEffect pour lancer le recompute **avant** de changer le dom
   */
  useLayoutEffect(() => {
    tableRef.current && tableRef.current.recomputeGrids();
  }, [gridPadding]);

  const entities = useMemo(() => {
    return mergeDatatableData(
      state.data.newEntitiesStart,
      state.data.entities,
      state.data.newEntitiesLast,
      state.data.cache
    );
  }, [
    state.data.newEntitiesStart,
    state.data.entities,
    state.data.newEntitiesLast,
    state.data.cache
  ]);

  const isDirty = useMemo(() => {
    const keys = Object.keys(entities);
    let entity: Pojo | "LOADING_POJO" | undefined;

    for (let key of keys) {
      entity = entities[key];
      if (entity && entity !== "LOADING_POJO" && entity.modifie === true) {
        return true;
      }
    }
    return false;
  }, [entities]);
  const { setDirty } = useDirtyGalaxy();
  // on change la valeur ici à chaque reload pour être sur d'être sync.
  // on ne souhaite par contre pas mettre à jour react suite à ce type de changement
  // du coup, le set ici utilise une ref.
  setDirty(props.sjmoCode, props.ctrlkey, isDirty);

  useEffect(() => {
    if (state.state === "DEFINITION_OK" || state.state === "REFETCH") {
      const source = Axios.CancelToken.source();
      requestTokens.current.push(source);
      fetchData(0, MIN_BATCH_SIZE, true, source).then(() => {
        tableRef.current && tableRef.current.recomputeGrids();
      });
    }
    return () => {};
  }, [fetchData, state.state]);

  useEffect(() => {
    if (state.state === "RELOAD_FROM_CACHE") {
      if (isDirty) {
        dispatch({ type: "UPDATE_DATATABLE_STATE", payload: "IDLE" });
      } else {
        const source = Axios.CancelToken.source();
        requestTokens.current.push(source);
        fetchData(0, MIN_BATCH_SIZE, true, source).then(() => {
          tableRef.current && tableRef.current.recomputeGrids();
        });
      }
    }
    return () => {};
  }, [fetchData, isDirty, state.state]);

  useEffect(() => {
    if (state.state === "RECOMPUTE") {
      requestAnimationFrame(() => {
        tableRef.current && tableRef.current.recomputeGrids();
      });
      dispatch({ type: "UPDATE_DATATABLE_STATE", payload: "IDLE" });
    }
  }, [fetchData, state.state]);

  // permet de s'assurer que l'on a la dernière version de saveRows lors de l'appel via le useEffect ci-dessous.
  const saveRowsWhenPending = useRef(saveRows);
  saveRowsWhenPending.current = saveRows;

  useEffect(() => {
    if (state.state === "SAVE_PENDING") {
      saveRowsWhenPending.current();
    }
    // On ne met pas le saveRows car une modification de la fonction ne doit pas déclencher un save.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.state]);

  useEffect(() => {
    if (state.state === "WVI_WAITING" && state.wvi.waiting.id && state.wvi.waiting.field) {
      wvi(state.wvi.waiting.id, state.wvi.waiting.field);
    }
  }, [state.state, state.wvi.waiting.field, state.wvi.waiting.id, wvi]);

  const prevRef = useRef(props.interactions);
  const requestTokens = useRef<CancelTokenSource[]>([]);
  useEffect(() => {
    // cancel token nous permet d'annuler une requête si on change
    // d'avis.
    //
    // exemple : une interaction change rapidement.
    //
    // Donc si on a une interaction avec un ID=1 puis rapidement un ID = 2.
    // L'appel pour ID=1 n'est plus valide, on ne veut donc pas afficher
    // des données incorrectes à l'utilisateur. On souhaite juste avoir les données venant de l'ID 2.
    //
    //           user                server
    // id = 1     | ---- fetch 1 --->  |
    // id = 2     |        x---------  |
    //            | ---- fetch 2 --->  |
    //            | <----------------  |
    //
    // cela fonctionne aussi avec ID=1 puis on annule en faisant ID = null.
    //
    // comme on peut le voir, l'annulation nous évites devoir vider manuellement les données locales
    // parce que le cancel nous a directement permis d'éviter de garder des données invalides
    // Si l'interraction change il faut cancel toutes les requêtes (fetchData) en cours car toutes les requêtes précédentes sont fausses
    if (!isEqual(prevRef.current, props.interactions)) {
      const cancelToken = Axios.CancelToken.source();
      requestTokens.current.forEach(rqToken => rqToken.cancel());
      requestTokens.current = [];
      requestTokens.current.push(cancelToken);
      dispatch({ type: "FOCUS_ROW", payload: null });
      fetchData(0, MIN_BATCH_SIZE, true, cancelToken);
    }
    prevRef.current = props.interactions;
  }, [fetchData, props.interactions]);

  useEffect(() => {
    if (state.state === "RELOAD_FROM_CACHE") {
      const keys = Object.keys(entities);
      const fields = Object.keys(props.interactions);
      for (let key of keys) {
        // on ignore les entités non présentes
        if (entities[key] === "LOADING_POJO" || entities[key] === undefined) continue;

        for (let field of fields) {
          const interactionValue = props.interactions[field] || null;
          const masterField = field.split(".")[0];
          const fieldValue = get(entities, [key, masterField], null);

          if (interactionValue !== fieldValue) {
            // Le clear filter déclenche lui même un refresh data
            dispatch({ type: "CLEAR_CLOSE_FILTERS" });
          }
        }
      }
    }
    // on désactive le check des deps ici. Parce qu'on veut vraiment le lancer que la première fois
    // (¬_¬ )
    // il n'y a que sur le premier chargement, avec un CACHE, que l'on peut avoir
    // une incohérence d'interactions entre le cache et l'interaction courante.
    // on a donc un state.state === "RELOAD_FROM_CACHE"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.focusedRow) {
      const entity = entities[state.focusedRow];
      if (entity && entity !== "LOADING_POJO") {
        reduxDispatch(contextualize(props.sjmoCode, props.tableName, props.ctrlkey, entity));
      }
    } else if (entities[0] !== undefined && entities[0] !== "LOADING_POJO") {
      reduxDispatch(contextualize(props.sjmoCode, props.tableName, props.ctrlkey, entities[0]));
    } else if (Object.keys(entities).length === 0) {
      // si on a plus aucune entité, on veut être sûr de décontextualiser la datatable
      reduxDispatch(contextualize(props.sjmoCode, props.tableName, props.ctrlkey, null));
    }
  }, [entities, props.ctrlkey, props.sjmoCode, props.tableName, reduxDispatch, state.focusedRow]);

  useEffect(() => {
    if (!isDirty) {
      changedFieldsRef.current = {};
    }
  }, [isDirty]);

  function loadMoreData({
    startIndex,
    stopIndex
  }: {
    startIndex: number;
    stopIndex: number;
  }): Promise<any> {
    const source = Axios.CancelToken.source();
    requestTokens.current.push(source);
    return fetchData(startIndex, stopIndex, false, source);
  }

  function handleSelection(rowIndex: string | "ALL") {
    if (props.selectionActive) {
      dispatch({ type: "UPDATE_SELECTED_ROWS_DATATABLE", payload: { rowIndex } });
      tableRef.current && tableRef.current.recomputeGrids();
    }
  }

  const onAdd = useCallback(
    (position: "START" | "END" = "START") => {
      track("datatable::add", { direction: position });
      return api
        .preRecord({
          sjmoCode: props.sjmoCode,
          tableName: props.tableName,
          context: props.interactions
        })
        .then(res => {
          track("datatable::add", { status: "finished" });
          dispatch({
            type: "ADD_ROW_DATATABLE",
            payload: { position, pojo: { ...res.data, modifie: true } }
          });
        })
        .catch(err => {
          track("datatable::add", { status: "error" });
          console.error("error during fetch precord data");
          console.error(err);
        });
    },
    [props.interactions, props.sjmoCode, props.tableName]
  );

  function getFilter() {
    return getRSQLFilter(
      props.interactions,
      state.userFilter.searchValues,
      state.userFilter.sortValues,
      state.breakRows.columns,
      props.additionnalClause,
      allColumnRef.current
    ).build();
  }

  const onChange = useCallback(
    function onChange(entityId: string, field: string, value: any) {
      const fieldChanged = get(changedFieldsRef.current, [entityId, field], false);
      const valueChanged = value !== get(state.data.cache, [entityId, field]);

      if (!fieldChanged && valueChanged) {
        set(
          changedFieldsRef.current,
          [entityId, field],
          value !== get(state.data.cache, [entityId, field])
        );
      }

      const column = state.definitions.columns.find(col => col.column === field);
      const hasWVI =
        (column &&
          (column.typeCompo === "GS" ||
            column.typeCompo === "SD" ||
            column.typeCompo === "CH" ||
            column.typeCompo === "GSV" ||
            column.typeCompo === "GSVL") &&
          column.wvi &&
          get(changedFieldsRef.current, [entityId, field], false)) ||
        false;
      dispatch({ type: "CHANGE_ROW_DATATABLE", payload: { entityId, field, value, hasWVI } });
    },
    [state.data.cache, state.definitions.columns]
  );

  const onBlur = useCallback(
    function onBlur(entityId: string, field: string) {
      const column = state.definitions.columns.find(col => col.column === field);

      if (
        column &&
        column.typeCompo !== "GS" &&
        column.typeCompo !== "GSV" &&
        column.typeCompo !== "GSVL" &&
        column.typeCompo !== "SD" &&
        column.typeCompo !== "CH" &&
        get(changedFieldsRef.current, [entityId, field], false)
      ) {
        wvi(entityId, field);
      }
    },
    [state.definitions.columns, wvi]
  );

  const onContextMenu = useCallback(
    function onContextMenu(
      entityId: string,
      field: string,
      event: MouseEvent<any>,
      useView?: boolean
    ) {
      // on inverse le behavior pour les datatables
      if (event.ctrlKey) {
        return;
      }

      event.preventDefault();
      const dimension = getDimensionFromEvent(event);
      const entity = state.data.cache[entityId];

      if (entity) {
        reduxDispatch(
          showContextMenu(
            dimension.x,
            dimension.y,
            props.sjmoCode,
            props.tableName,
            field,
            entity.id,
            entity[field],
            null, // genericEntity uniquement pour les listes génériques,
            undefined,
            false,
            true
          )
        );
      }
    },
    [props.sjmoCode, props.tableName, reduxDispatch, state.data.cache]
  );

  const onDeleteRow = useCallback(
    function onDeleteRow(index: number) {
      const entity = entities[index];
      if (!entity || entity === "LOADING_POJO") {
        return;
      }

      track("datatable::delete::unique", { module: props.sjmoCode, tableName: props.tableName });

      if (entity.id === null) {
        dispatch({
          type: "REMOVE_NEW_ENTITIES_DATATABLE",
          payload: { toBeRemove: [entity.uuid], unSelect: true }
        });
      } else {
        api
          .deleteMany(props.tableName, [entity.id], props.sjmoCode)
          .then(() => {
            refresh().then(() => {
              props.onAfterDeleteDatatable && props.onAfterDeleteDatatable();
            });
          })
          .catch(e => {
            console.error(
              "error during save of Datatable ",
              props.tableName,
              "with the key=",
              props.ctrlkey
            );
            console.error(e);
          });
      }
    },
    [entities, props, refresh]
  );

  const onRowClickParent = props.onRowClick;
  const onRowClick = useCallback(
    (index: number | null) => {
      if (index === null) {
        dispatch({ type: "FOCUS_ROW", payload: null });
        reduxDispatch(contextualize(props.sjmoCode, props.tableName, props.ctrlkey, null));
      } else {
        const entity = entities[index];

        if (entity && entity !== "LOADING_POJO") {
          if (onRowClickParent && grantOnRowClick) {
            onRowClickParent(entity.id);
          } else {
            // Le grantOnRowClick est là pour annuler 1 seul rowClick lors du click sur un lien vers les données satellite
            // il doit donc être remit à true dès qu'un click est annulé
            setGrantOnRowClick(true);
          }

          dispatch({ type: "FOCUS_ROW", payload: index });

          const shouldSendNull = entity.id == null;
          reduxDispatch(
            contextualize(
              props.sjmoCode,
              props.tableName,
              props.ctrlkey,
              shouldSendNull ? null : entity
            )
          );
        }
      }
    },
    [
      entities,
      onRowClickParent,
      props.ctrlkey,
      props.sjmoCode,
      props.tableName,
      reduxDispatch,
      grantOnRowClick
    ]
  );

  const duplicateRow = useCallback(
    function duplicateRow(rowIndex: number, direction: "UP" | "DOWN") {
      const pojo = entities[rowIndex];

      if (pojo && pojo !== "LOADING_POJO") {
        track("datatable::duplicate", { module: props.sjmoCode, tableName: props.tableName });
        api
          .duplicate(props.sjmoCode, props.tableName, pojo, props.interactions)
          .then(res => {
            const entity = res.data;
            entity.modifie = true;

            dispatch({
              type: "DUPLICATE_ROW",
              payload: { entityID: pojo.id || pojo.uuid, rowIndex, direction, duplicated: entity }
            });
          })
          .catch(err => {
            console.error("error during fetch precord data");
            console.error(err);
          });
      }
    },
    [entities, props.interactions, props.sjmoCode, props.tableName]
  );

  /**
   * Cette ref permet lors d'un ctrl + entrée de décaler le OnBlur et les save afin qu'ils ne se lancent pas dans le même render.
   * De cette façon on agit éxactement comme si l'utilisateur avait manuellement cliqué sur le bouton de sauvegarde.
   */
  const wviAndSaveRef = useRef<Boolean>(false);
  useEffect(() => {
    if (wviAndSaveRef.current) {
      wviAndSaveRef.current = false;
      saveRows();
    }
  });

  const onKeyDown = useCallback(
    (rowIndex: number, columnIndex: number, event: KeyboardEvent<any>) => {
      // pour garder le behavior par défaut du navigateur
      // on quitte la fonction sur jamais on a pas appuyé sur les `ctrl`
      if (!event.ctrlKey || props.readOnly) {
        return;
      }

      if (event.key === "Insert") {
        track("datatable::shortcut::insert", {
          module: props.sjmoCode,
          tableName: props.tableName
        });
        if (currentFocus && currentFocus.insertable) {
          onAdd().then(() => {
            requestAnimationFrame(() => {
              let selection = props.selectionActive ? 1 : 0;
              let action = props.showActionColumns ? 1 : 0;
              let firstColumn = selection + action + 1;
              executeNavigationMove(props.ctrlkey, 0, firstColumn);
            });
          });
        }
        return;
      }

      // si jamais on appuie sur ctrl+entrer, on lance une sauvegarde
      if (event.key === "Enter") {
        track("datatable::shortcut::save", {
          module: props.sjmoCode,
          tableName: props.tableName
        });

        const entity = entities[rowIndex];
        if (entity && entity !== "LOADING_POJO") {
          // pas le droit d'insert / update
          // on coupe le raccourci.
          if (!(currentFocus && (currentFocus.insertable || currentFocus.updatatable))) {
            return;
          }

          const field = (event.target as any).name;
          const column = state.definitions.columns.find(col => col.column === field);

          // si la colonne a un wvi, on lance le wvi & la sauvegarde
          // sinon, on lance la sauvegarde directement
          if (column?.wvi === true) {
            wviAndSaveRef.current = true;
            onBlur(entity.id ? entity.id : entity.uuid, field);
          } else {
            saveRows();
          }
        }

        return;
      }

      // on supprime avec ctrl+Delete/Suppr
      if (event.key === "Delete") {
        track("datatable::shortcut::delete", {
          module: props.sjmoCode,
          tableName: props.tableName
        });
        if (currentFocus && currentFocus.deletable) {
          onDeleteRow(rowIndex);
        }
        return;
      }

      if (event.altKey && (event.key === "ArrowDown" || event.key === "ArrowUp")) {
        event.preventDefault();
        if (currentFocus && currentFocus.insertable) {
          duplicateRow(rowIndex, event.key === "ArrowDown" ? "DOWN" : "UP");
        }
        return;
      }

      let row: number = -1;
      let column: number = -1;

      let authorizeNewRowAtTheEnd = true;

      if (event.key === "ArrowDown") {
        track("datatable::shortcut::move::down", {
          module: props.sjmoCode,
          tableName: props.tableName
        });
        let maxRow = authorizeNewRowAtTheEnd ? totalRecords : totalRecords - 1;

        row = Math.min(rowIndex + 1, maxRow);
        column = columnIndex;
        requestAnimationFrame(() => {
          onRowClick(row);
        });
      } else if (event.key === "ArrowUp") {
        track("datatable::shortcut::move::up", {
          module: props.sjmoCode,
          tableName: props.tableName
        });
        row = Math.max(rowIndex - 1, 0);
        column = columnIndex;
        requestAnimationFrame(() => {
          onRowClick(row);
        });
      }

      if (row !== -1 && column !== -1) {
        event.preventDefault();
        if (row === totalRecords && authorizeNewRowAtTheEnd) {
          onAdd("END").then(() => {
            let selection = props.selectionActive ? 1 : 0;
            let action = props.showActionColumns ? 1 : 0;
            let firstColumn = selection + action;
            requestAnimationFrame(() => {
              executeNavigationMove(props.ctrlkey, row, firstColumn);
            });
          });
        } else {
          executeNavigationMove(props.ctrlkey, row, column);
        }
      }
    },
    [
      currentFocus,
      onAdd,
      props.selectionActive,
      props.showActionColumns,
      props.ctrlkey,
      saveRows,
      onDeleteRow,
      duplicateRow,
      totalRecords,
      onRowClick
    ]
  );

  const dtContext = useMemo(() => {
    let readonly: ComponentReadOnly = ComponentReadOnly.DEFAULT;

    if (currentFocus) {
      if (currentFocus.insertable === false && currentFocus.updatatable === true) {
        readonly = ComponentReadOnly.READ_ONLY_INSERT;
      }

      if (currentFocus.updatatable === false && currentFocus.insertable === true) {
        readonly = ComponentReadOnly.READ_ONLY_UPDATE;
      }

      if (currentFocus.insertable === false && currentFocus.updatatable === false) {
        readonly = ComponentReadOnly.READ_ONLY;
      }
    }

    return {
      sjmoCode: props.sjmoCode,
      tableName: props.tableName,
      ctrlKey: props.ctrlkey,
      onChange,
      onBlur,
      onKeyDown,
      onContextMenu,
      gridPadding,
      wviState: state.wvi.state,
      readonly
    };
  }, [
    currentFocus,
    gridPadding,
    onBlur,
    onChange,
    onContextMenu,
    onKeyDown,
    props.ctrlkey,
    props.sjmoCode,
    props.tableName,
    state.wvi.state
  ]);

  function fetchRowExpand(id: string) {
    if (state.definitions.selectedFocus) {
      track("datatable::rowexpand", { sjmoCode: props.sjmoCode, tableName: props.tableName });
      if (state.rowExpand.open[id]) {
        dispatch({ type: "TOGGLE_ROW_EXPAND", payload: id });
      } else {
        api
          .getRowExpand(props.sjmoCode, state.definitions.selectedFocus, props.tableName, id)
          .then(res => {
            dispatch({
              type: "ROW_EXPAND_DATA",
              payload: { id, result: res.data }
            });
          })
          .catch(e => {
            console.error("cannot fetch the expanded row data for ", id);
            console.error(e);
          });
      }
    }
  }

  function prepareExportData(type: ExportType, columns: Pojo[], useTrad: boolean) {
    if (columns.length === 0) {
      const message: Message = {
        type: "DANGER",
        message: t("commun_au_moins_1_champs_export"),
        target: "GLOBAL"
      };
      reduxDispatch(addMessage(message));
      return;
    }
    // L'export attend une liste de componentState avec un minimum d'informations.
    const chosenComponents: ComponentState[] = columns.map((pojo, index) => {
      return {
        column: pojo.syjColumnId.sjcoColumn as string,
        label: useTrad ? (pojo.label as string) : pojo.syjColumnId.sjcoColumnName,
        typeCompo: pojo.syjColumnId.sjcoTypeCompo as string,
        position: index
      } as ComponentState;
    });
    if (type === "XLS") {
      track("datatable::export", { type: "xls" });
      exportData(api.callExportXls, chosenComponents);
    } else {
      track("datatable::export", { type: "pdf" });
      exportData(api.callExportPdf, chosenComponents);
    }
  }

  function exportData(
    callExportApi: (
      sjmoCode: string,
      tableName: string,
      filter: string,
      columns: ComponentState[]
    ) => AxiosPromise<string>,
    chosenComponents: ComponentState[]
  ) {
    // On récupère le filtre actuellement appliqué à la datatable pour selectionner les données à exporter
    const filter = getFilter();
    callExportApi(props.sjmoCode, props.tableName, filter, chosenComponents)
      .then(res => {
        let fakeLink = document.createElement("a");
        const host = URL_DOWNLOAD();
        const uri = res.data;

        fakeLink.href = encodeURI(
          `${host}${uri}${auth.token ? "&access_token=" + auth.token : ""}`
        );

        fakeLink.click();
      })
      .catch(e => {
        console.log(e);
        console.error(
          "cannot export data for the ",
          props.tableName,
          "on the module",
          props.sjmoCode,
          " with the filter '",
          filter,
          "'"
        );
      });
  }

  const breakRows: BreakRow[] = useMemo(() => {
    let myBreakLabels: any;
    if (state.definitions.selectedFocus) {
      myBreakLabels = state.definitions.focus
        .filter(focus => focus.focusId === state.definitions.selectedFocus)
        .map(focus => focus.breakLabel)
        .flat();
    }

    if (myBreakLabels && myBreakLabels?.length === state.breakRows.columns.length) {
      return state.breakRows.columns.map((col, index) => {
        const comp = state.definitions.columns.find(c => c.column === col);
        return {
          // Ajout du replace pour remplacer le . par un _ car pas de . dans les tabs
          col: myBreakLabels[index] !== null ? myBreakLabels[index].replace(/\./g, "_") : col,
          label: comp ? comp.label : col
        };
      });
    } else {
      return state.breakRows.columns.map(col => {
        const comp = state.definitions.columns.find(c => c.column === col);
        return { col, label: comp ? comp.label : col };
      });
    }
  }, [state.breakRows.columns, state.definitions.columns]);

  const selectedIndexes = useMemo(() => {
    const entries = Object.entries(entities);

    return entries
      .filter(
        ([_, entity]) =>
          checkEntityValid(entity) &&
          state.userFilter.selectedRows.includes(entity.id ?? entity.uuid)
      )
      .map(([index]) => Number(index));
  }, [entities, state.userFilter.selectedRows]);

  const selectedEntities = useMemo(() => {
    return selectedIndexes.map(entityID => entities[entityID]) as Pojo[];
  }, [entities, selectedIndexes]);

  if (props.hidden) {
    return null;
  }

  return (
    <ErrorBoundary>
      <DatatableContext.Provider value={dtContext}>
        <ProcessusProvider
          sjmoCode={props.sjmoCode}
          selected={selectedEntities}
          tableName={props.tableName}
          onAfterSaveProcess={props.onAfterSaveDatatable}
        >
          <Table
            minimumBatchSize={MIN_BATCH_SIZE}
            threshold={MIN_BATCH_SIZE}
            rowHeight={gridPadding === "small" ? 40 : 60}
            ctrlKey={props.ctrlkey}
            gridPadding={gridPadding}
            toolbar={
              <DatatableToolbar
                sjmoCode={props.sjmoCode}
                tableName={props.tableName}
                ctrlkey={props.ctrlkey}
                toolbarButtonVisibility={props.toolbarButtonVisibility}
                state={state}
                isDirty={isDirty}
                entities={entities}
                dispatch={dispatch}
                refresh={refresh}
                saveRows={saveRows}
                gridPadding={gridPadding}
                setGridPadding={setGridPadding}
                getFilter={getFilter}
                onAdd={onAdd}
                onAfterDeleteDatatable={props.onAfterDeleteDatatable}
                selectedRow={state.focusedRow}
                onRowClick={onRowClick}
                exportProps={exportProps}
                setExportProps={setExportProps}
              />
            }
            ref={tableRef}
            selectedRows={selectedIndexes}
            entities={entities}
            totalRecords={totalRecords}
            loadMoreData={loadMoreData}
            rowExpand={state.rowExpand.open}
            rowExpandData={state.rowExpand.data}
            rowExpandRenderer={expandedProps => {
              const entity = entities[expandedProps.rowIndex];
              const value =
                entity !== undefined && entity !== "LOADING_POJO"
                  ? state.rowExpand.data[entity.id || entity.uuid] || ""
                  : "";

              return (
                <div key={expandedProps.key} style={expandedProps.style}>
                  <div
                    style={{ width: "100%", height: "100%", overflow: "scroll", padding: "1em" }}
                  >
                    <React.Suspense fallback={<LoadableLoader />}>
                      <GenericMarkdownDisplay value={value} />
                    </React.Suspense>
                  </div>
                </div>
              );
            }}
            onRowClick={onRowClick}
            onReorderColumnEnd={({
              oldIndex,
              newIndex
            }: {
              oldIndex: number;
              newIndex: number;
            }) => {
              // on doit enlever les colonnes supplémentaires "statiques" qui ne figure pas
              // dans la définition
              let shift = 0;
              props.selectionActive && shift++;
              props.showActionColumns && shift++;
              currentFocus && currentFocus.rowExpandable && shift++;

              let correctOldIndex = oldIndex - shift;
              let correctNewIndex = newIndex - shift;

              if (
                correctOldIndex >= 0 &&
                correctNewIndex >= 0 &&
                correctNewIndex !== correctOldIndex
              ) {
                track("datatable::column::reorder");
                const newColumnsOrder = arrayMove(
                  state.definitions.columns,
                  correctOldIndex,
                  correctNewIndex
                );
                dispatch({
                  type: "UPDATE_COLUMNS",
                  payload: newColumnsOrder
                });
                tableRef.current && tableRef.current.recomputeGrids();
              }
            }}
            breakRowColumn={breakRows}
            isLoading={state.globalLoading}
            isFilterVisible={state.userFilter.open}
            selectedRow={state.focusedRow}
          >
            {props.selectionActive && (
              <TableColumn
                key={DATATABLE_SELECTION.COMPONENT.column}
                column={DATATABLE_SELECTION.COMPONENT}
                width={DATATABLE_SELECTION.COMPONENT.contentSize}
                component={SimpleComponent[DATATABLE_SELECTION.COMPONENT.typeCompo]}
                renderHeaderCell={({ key, style, className }) => {
                  return (
                    <div
                      key={key}
                      className={classNames(className, "cursor-pointer")}
                      style={style}
                    >
                      <input
                        ref={el => {
                          const isIndeterminate =
                            state.userFilter.selectedRows.length > 0 &&
                            state.userFilter.selectedRows.length < state.data.totalRecords;

                          if (el) el.indeterminate = isIndeterminate;
                        }}
                        type="checkbox"
                        checked={
                          state.userFilter.selectedRows &&
                          totalRecords > 0 &&
                          state.userFilter.selectedRows.length === totalRecords
                        }
                        className="cursor-pointer"
                        style={{
                          width: gridPadding === "small" ? 15 : 20,
                          height: gridPadding === "small" ? 15 : 20
                        }}
                        onChange={() => handleSelection("ALL")}
                      />
                    </div>
                  );
                }}
              >
                {({ className, style, rowIndex, colorRow, data }) => {
                  const entity = entities[rowIndex];
                  const isRowSelected =
                    checkEntityValid(entity) &&
                    state.userFilter.selectedRows.includes(entity.id ?? entity.uuid);
                  const rowClassName = classNames(className, data?.className ?? null);
                  return (
                    <div
                      className={rowClassName}
                      style={{ ...style, cursor: "pointer" }}
                      data-state-row={colorRow}
                      onClick={() => onRowClick(rowIndex)}
                    >
                      <input
                        tabIndex={-1}
                        style={{
                          width: gridPadding === "small" ? 15 : 20,
                          height: gridPadding === "small" ? 15 : 20,
                          cursor: "pointer"
                        }}
                        type="checkbox"
                        checked={isRowSelected}
                        onChange={() =>
                          checkEntityValid(entity) && handleSelection(entity.id ?? entity.uuid)
                        }
                      />
                    </div>
                  );
                }}
              </TableColumn>
            )}
            {props.showActionColumns && (
              <TableColumn
                key={DATATABLE_ACTION.COMPONENT.column}
                column={DATATABLE_ACTION.COMPONENT}
                width={DATATABLE_ACTION.COMPONENT.contentSize}
                component={SimpleComponent[DATATABLE_ACTION.COMPONENT.typeCompo]}
                renderHeaderCell={({ key, style, className }) => {
                  return <div key={key} className={className} style={style} />;
                }}
              >
                {({ className, style, data, colorRow, rowIndex }) => {
                  const existSatelliteData =
                    (state.satellitesExist && data && state.satellitesExist[data.id]) || false;
                  const rowClassName = classNames(className, data?.className ?? null);

                  return (
                    <div
                      className={rowClassName}
                      style={style}
                      data-state-row={colorRow}
                      onClick={() => onRowClick(rowIndex)}
                    >
                      {data ? (
                        <SatelliteMenuButton
                          isActive={data.id && data.id.split("~").length < 4 ? true : false}
                          sjmoCode={props.sjmoCode}
                          tableName={props.tableName}
                          entityId={data.id}
                          ctrlKey={props.ctrlkey}
                          filter={getRSQLFilter(
                            props.interactions,
                            state.userFilter.searchValues,
                            state.userFilter.sortValues,
                            state.breakRows.columns,
                            props.additionnalClause,
                            allColumnRef.current
                          ).build()}
                          columns={state.definitions.columns.slice(0, 4).map(col => col.column)}
                          className={`is-text ${
                            existSatelliteData ? "has-text-link" : "has-text-dark"
                          }`}
                        >
                          <Fa icon={[existSatelliteData ? "fad" : "far", "satellite"]} />
                        </SatelliteMenuButton>
                      ) : null}
                    </div>
                  );
                }}
              </TableColumn>
            )}
            {currentFocus && currentFocus.rowExpandable && (
              <TableColumn
                key={DATATABLE_EXPAND.COMPONENT.column}
                column={DATATABLE_EXPAND.COMPONENT}
                width={DATATABLE_EXPAND.COMPONENT.contentSize}
                component={SimpleComponent[DATATABLE_EXPAND.COMPONENT.typeCompo]}
                renderHeaderCell={({ key, className, style }) => {
                  return <div key={key} className={className} style={style} />;
                }}
              >
                {({ className, style, data, colorRow, rowIndex }) => {
                  const id = data.id || data.uuid;
                  const iconArrowDirection: IconName = state.rowExpand.open[id]
                    ? "arrow-circle-up"
                    : "arrow-circle-down";
                  const rowClassName = classNames(className, data?.className ?? null);
                  return (
                    <div
                      className={rowClassName}
                      style={style}
                      data-state-row={colorRow}
                      onClick={() => onRowClick(rowIndex)}
                    >
                      {data ? (
                        <ButtonNoStyle
                          className="button is-text"
                          onClick={() => {
                            let id = data.id || data.uuid;
                            fetchRowExpand(id);
                          }}
                        >
                          <span className="icon">
                            <Fa icon={iconArrowDirection} />
                          </span>
                        </ButtonNoStyle>
                      ) : null}
                    </div>
                  );
                }}
              </TableColumn>
            )}
            {state.definitions.columns.map(col => {
              const workingCol = {
                ...col,
                readOnly: props.readOnly === true ? ComponentReadOnly.READ_ONLY : col.readOnly
              };
              const width = workingCol.contentSize;

              return (
                <TableColumn
                  key={workingCol.column}
                  className={workingCol.typeCompo === "ED" ? "cell-editor" : undefined}
                  column={workingCol}
                  width={width}
                  component={SimpleComponent.I}
                  renderHeaderCell={({ columnIndex, key, parent, style }) => {
                    return (
                      <SortableHeaderItem
                        key={key}
                        index={columnIndex}
                        column={workingCol.column}
                        typeCompo={workingCol.typeCompo}
                        label={workingCol.label}
                        tooltip={workingCol.tooltip}
                        filterOpen={state.userFilter.open}
                        style={style}
                        required={workingCol.mandatory}
                        sort={state.userFilter.sortValues[workingCol.column]}
                        onSortAsc={() => {
                          track("datatable::sort::asc");
                          dispatch({
                            type: "TOGGLE_SORT",
                            payload: { name: col.column, sort: "ASC" }
                          });
                          tableRef.current && tableRef.current.recomputeHeader();
                        }}
                        onSortDesc={() => {
                          track("datatable::sort::desc");
                          dispatch({
                            type: "TOGGLE_SORT",
                            payload: { name: col.column, sort: "DESC" }
                          });
                          tableRef.current && tableRef.current.recomputeHeader();
                        }}
                        onResize={(_, { deltaX }) => {
                          track("datatable::column::resize");
                          dispatch({
                            type: "RESIZE_COLUMN",
                            payload: {
                              column: col.column,
                              width: (parent as any).props.width,
                              deltaX
                            }
                          });
                          tableRef.current && tableRef.current.recomputeGrids();
                        }}
                      >
                        <TableHeaderItemFilter
                          column={workingCol.column}
                          typeCompo={workingCol.typeCompo}
                          filterOpen={state.userFilter.open}
                          filterValues={state.userFilter.searchValues}
                          sysDomaineChoices={workingCol.sysDomaineChoices}
                          onFilterChange={(name, value, operator) => {
                            if (workingCol.typeCompo === "CH") {
                              switch (value) {
                                case "true":
                                  value = true;
                                  break;
                                case "false":
                                  value = false;
                                  break;
                                default:
                                  value = null;
                              }
                            }

                            dispatch({
                              type: "SEARCH_VALUES",
                              payload: {
                                name: name,
                                value: operator ? operator + value : value,
                                needRefresh: ["CH", "SD", "CA", "CAH"].includes(col.typeCompo)
                              }
                            });
                            tableRef.current && tableRef.current.recomputeHeader();
                          }}
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              track("datatable::filter::advanced", {
                                sjmoCode: props.sjmoCode,
                                tableName: props.tableName
                              });
                              refresh();
                            }
                          }}
                        />
                      </SortableHeaderItem>
                    );
                  }}
                >
                  {renderCell}
                </TableColumn>
              );
            })}
          </Table>
        </ProcessusProvider>
        {state.breakRows.dialogOpen && (
          <BreakRowDialog
            sjmoCode={props.sjmoCode}
            columns={state.definitions.columns}
            initialBreakRow={state.breakRows.columns}
            onClose={() => dispatch({ type: "TOGGLE_BREAKS_ROWS_DIALOG" })}
            onValidate={newRows => dispatch({ type: "CHANGE_BREAK_ROWS", payload: newRows })}
          />
        )}
      </DatatableContext.Provider>
      {exportProps != null && exportProps.showExport === true && (
        <DragNDropContext>
          <SelectFieldsModal
            title="commun_choisir_champs_a_exporter"
            defaultChosenFields={exportProps.chosen}
            allFields={exportProps.available}
            onValidate={(columns: Pojo[], useTrad: boolean) =>
              prepareExportData(exportProps.type, columns, useTrad)
            }
            onClose={() => setExportProps({ ...exportProps, showExport: false })}
          />
        </DragNDropContext>
      )}
    </ErrorBoundary>
  );
};

Datatable.defaultProps = {
  selectionActive: true,
  showActionColumns: true,
  clearCacheOnClose: false,
  hidden: false
};

export default withDatableDragNDrop(withDatatableCache(Datatable));

const renderCell = ({
  className,
  col,
  colorRow,
  columnIndex,
  data,
  rowIndex,
  onRowClick,
  style
}: CustomRenderProperties) => {
  const customID = data ? data.id || data.uuid : rowIndex + "-" + columnIndex;
  let customKey = `${rowIndex}-${col.column.column}-${customID}`;

  const rowClassName = classNames(className, data?.className ?? null);

  return (
    <div
      key={customKey}
      className={rowClassName}
      style={style}
      data-state-row={colorRow}
      onClick={() => onRowClick && onRowClick(rowIndex)}
    >
      <DatatableComponents
        column={col.column}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        entity={data}
      />
    </div>
  );
};

const DatatableToolbar: FC<{
  sjmoCode: string;
  tableName: string;
  ctrlkey: string;
  state: DatatableState;
  entities: Record<string, Pojo | "LOADING_POJO" | undefined>;
  dispatch: Dispatch<AllDatatableActions>;
  isDirty: boolean;
  toolbarButtonVisibility?: ToolbarButtonOverride;
  gridPadding: GridPaddingType;
  selectedRow: number | null;
  exportProps: {
    available: Pojo[];
    chosen: Pojo[];
    type: ExportType;
    showExport: boolean;
  } | null;
  onAdd(): void;
  saveRows(): Promise<any>;
  refresh(): Promise<any>;
  getFilter(): string;
  onAfterDeleteDatatable?(): void;
  setGridPadding(gridPadding: GridPaddingType): void;
  onRowClick(index: number | null): void;
  setExportProps(params: {
    available: Pojo[];
    chosen: Pojo[];
    type: ExportType;
    showExport: boolean;
  }): void;
}> = ({
  sjmoCode,
  tableName,
  ctrlkey,
  state,
  entities,
  dispatch,
  refresh,
  isDirty,
  gridPadding,
  selectedRow,
  toolbarButtonVisibility,
  onAdd,
  getFilter,
  setGridPadding,
  saveRows,
  onAfterDeleteDatatable,
  onRowClick,
  exportProps,
  setExportProps
}) => {
  const [t] = useTranslation();

  const {
    add: isAddButton,
    save: isSaveButton,
    refresh: isRefreshButton = true,
    delete: isDeleteButton,
    focus: isFocusButton = true,
    processus: isProcessusButton = true,
    satellite: isSatelliteButton = true,
    exportExcel: isExportExcell = true,
    exportPdf: isExportPdf = true
  } = toolbarButtonVisibility || ({} as ToolbarButtonOverride);

  const currentFocus = state.definitions.focus
    ? state.definitions.focus.find(f => f.focusId === state.definitions.selectedFocus)
    : null;

  function onDeleteRows() {
    track("datatable::delete::many", { sjmoCode, tableName });
    let localDelete: string[] = [];
    let remoteDelete: string[] = [];

    for (let id of state.userFilter.selectedRows) {
      const entity = state.data.cache[id];
      if (entity) {
        // pas d'id, donnée en cours de création
        if (entity.id === undefined || entity.id === null) {
          localDelete.push(entity.uuid);
        } else {
          remoteDelete.push(entity.id);
        }
      }
    }

    dispatch({
      type: "REMOVE_NEW_ENTITIES_DATATABLE",
      payload: { toBeRemove: localDelete, unSelect: remoteDelete.length === 0 }
    });

    if (remoteDelete.length > 0) {
      return api
        .deleteMany(tableName, remoteDelete, sjmoCode)
        .then(() => {
          return refresh();
        })
        .then(() => {
          // on désactive la contextualisation à chaque suppression
          onAfterDeleteDatatable && onAfterDeleteDatatable();
        })
        .catch(() => {
          console.error("error datatable during the delete");
        });
    } else {
      return Promise.resolve();
    }
  }

  const selectionCount = useMemo(() => {
    let local = 0;
    let remote = 0;
    let dirty = 0;
    const checkedId = new Set<string>();

    for (let id of state.userFilter.selectedRows) {
      let current = state.data.cache[id];
      if (checkEntityValid(current) && current.id == null) local++;
      if (checkEntityValid(current) && current.id != null) {
        remote++;
        if (current.modifie === true) dirty++;
      }
      checkedId.add(id);
    }

    const indexMainData = Object.keys(state.data.entities);
    for (let index of indexMainData) {
      let id = state.data.entities[index];
      if (checkedId.has(id)) continue;

      let entity = state.data.cache[state.data.entities[index]];
      if (checkEntityValid(entity) && entity.id != null && entity.modifie === true) dirty++;

      checkedId.add(id);
    }

    return { local: local, remote: remote, dirty: dirty };
  }, [state.data.cache, state.data.entities, state.userFilter.selectedRows]);

  function onFocusChange(selectedFocus: string) {
    dispatch({ type: "CHANGE_DATATABLE_FOCUS_SUCCESS", payload: { selectedFocus } });
  }

  function reloadFocuses() {
    loadDatatableDefinition(sjmoCode, tableName, ctrlkey).then(([focus, columns]) => {
      dispatch({ type: "FETCH_DEFINITIONS", payload: { focus, columns } });
    });
  }

  function onSaveFocusPerso() {
    if (currentFocus) {
      api
        .saveFocusPersoDatatable(
          currentFocus.focusId,
          tableName,
          ctrlkey,
          sjmoCode,
          state.definitions.columns,
          state.breakRows.columns
        )
        .then(() => {
          if (sessionStorage.getItem("superUser") !== "true") {
            reloadFocuses();
          }
        })
        .catch(e => {
          console.error(
            "error during the save of the modified focus ",
            currentFocus.focusCode,
            " of the datatable ",
            ctrlkey
          );
          console.error(e);
        });
    }
  }

  function onRazFocusPerso() {
    if (currentFocus) {
      api
        .razFocusPersoDatatable(currentFocus.focusId, sjmoCode)
        .then(() => {
          reloadFocuses();
        })
        .catch(e => {
          console.error(
            "error during the reset of the focus ",
            currentFocus.focusCode,
            " of the datatable ",
            ctrlkey
          );
          console.error(e);
        });
    }
  }

  function prepareExporDatatUI(type: ExportType) {
    if (state.definitions.columns && state.definitions.columns.length > 0) {
      // On exporte que les colonnes basées et référencées (BAR) dans le modèle hibernate
      const rsql = initRsqlCriteria();
      rsql.filters.and(
        new RSQLFilterExpression(
          "syjDatatableId.id",
          Operators.Equal,
          state.definitions.selectedFocus
        )
      );
      rsql.filters.and(
        new RSQLFilterExpression("syjColumnId.sjcoCategory", Operators.Equal, "BAR")
      );

      api
        .findAll({
          tableName: "syjDatatableColumns",
          filter: rsql.build(),
          includeJoinParent: true,
          first: 0,
          size: 999
        })
        .then(response => {
          // La liste de toutes les colonnes de l'entité valides pour l'export
          const available = response.data.data;
          // La liste, dans l'ordre, de toutes les colonnes présentent dans la datatable et valide pour l'export
          const chosen = state.definitions.columns
            .map(col => {
              const name = col.column;
              return available.find(pojo => pojo.syjColumnId.sjcoColumn === name) as Pojo;
            })
            .filter(it => it !== null && it !== undefined);
          setExportProps({ available, chosen, type, showExport: true });
        })
        .catch(e => console.log(e));
    }
  }

  function selectedFilterChange(e: SyntheticEvent<any>) {
    const selectedId = convertValue(e);
    const selectedFilter = state.definitions.filters.find(f => f.filterBarId === selectedId);

    track("datatable::filter::preconfigured", {
      sjmoCode,
      tableName,
      selectedId,
      start: selectedFilter?.startDate,
      end: selectedFilter?.endDate,
      label: selectedFilter?.label
    });
    dispatch({
      type: "DATATABLE_UPDATE_FILTER_BAR",
      payload: [
        { name: "filterBarId", value: selectedId },
        { name: "startDate", value: selectedFilter?.startDate },
        { name: "endDate", value: selectedFilter?.endDate }
      ]
    });
  }

  function startDateChange(e: SyntheticEvent<any>) {
    dispatch({
      type: "DATATABLE_UPDATE_FILTER_BAR",
      payload: [{ name: "startDate", value: convertValue(e, true) }]
    });
  }

  function endDateChange(e: SyntheticEvent<any>) {
    dispatch({
      type: "DATATABLE_UPDATE_FILTER_BAR",
      payload: [{ name: "endDate", value: convertValue(e, true) }]
    });
  }

  function toggleBreakRowDialog() {
    dispatch({ type: "TOGGLE_BREAKS_ROWS_DIALOG" });
  }

  function onClearFilter() {
    track("datatable::filter::clear");
    dispatch({ type: "CLEAR_FILTERS" });
  }

  const { selectedRows } = state.userFilter;
  if (selectedRows && selectedRows.length > 0) {
    let deletable = buttonVisibility(
      isDeleteButton,
      currentFocus ? currentFocus.deletable : undefined
    );

    const hasNewEntities =
      state.data.newEntitiesStart.length > 0 || state.data.newEntitiesLast.length > 0;

    const isDeleteButtonSelectionModeActive =
      (selectionCount.local > 0 && selectionCount.remote === 0 && selectionCount.dirty === 0) ||
      (!hasNewEntities && selectionCount.dirty === 0 && selectionCount.remote > 0);

    const isProcessButtonSelectionModeActive =
      !hasNewEntities && selectionCount.dirty === 0 && selectionCount.remote > 0;

    let showSelectionWarningMessage =
      !isDeleteButtonSelectionModeActive && !isProcessButtonSelectionModeActive;

    return (
      <Toolbar
        sjmoCode={sjmoCode}
        tableName={tableName}
        tableCtrlKey={ctrlkey}
        className={
          showSelectionWarningMessage ? "has-background-warning-light" : "has-background-blue-alt"
        }
      >
        <Toolbar.Left>
          <Toolbar.Item className="has-text-weight-semibold">
            <Trans
              i18nKey="commun_lignes_selectionnees"
              values={{ nb: selectedRows.length, total: state.data.totalRecords }}
            />
          </Toolbar.Item>

          <Toolbar.Item>
            <Field addons>
              {deletable && (
                <Toolbar.Delete
                  onDelete={isDeleteButtonSelectionModeActive ? onDeleteRows : undefined}
                  disabled={!isDeleteButtonSelectionModeActive}
                />
              )}
              {isProcessusButton && (
                <Toolbar.ProcessMenu disabled={!isProcessButtonSelectionModeActive} />
              )}
            </Field>
          </Toolbar.Item>
          {isFocusButton && (
            <Toolbar.Item>
              <Toolbar.FocusMenu
                focus={state.definitions.focus}
                selectedFocus={state.definitions.selectedFocus}
                onFocusChange={onFocusChange}
                onSaveFocusPerso={onSaveFocusPerso}
                onRazFocusPerso={onRazFocusPerso}
              />
            </Toolbar.Item>
          )}
          <Toolbar.Item>
            <button
              className="button is-link is-inverted"
              onClick={() => {
                track("datatable::rows::clearselected");
                dispatch({ type: "CLEAR_SELECTED_ROWS_DATATABLE" });
              }}
            >
              <Trans i18nKey="commun_annuler" />
            </button>
          </Toolbar.Item>
          <Toolbar.Item>
            {showSelectionWarningMessage ? (
              <span className="tags has-addons">
                <span className="tag is-black">{t("commun_attention")}</span>
                <span className="tag is-white">{t("commun_sauvegarder_avant_mode_selection")}</span>
              </span>
            ) : null}
          </Toolbar.Item>
        </Toolbar.Left>
      </Toolbar>
    );
  }

  let insertable = buttonVisibility(
    isAddButton,
    currentFocus ? currentFocus.insertable : undefined
  );
  let updatable = buttonVisibility(
    isSaveButton,
    currentFocus ? currentFocus.insertable || currentFocus.updatatable : undefined
  );

  let filterable = buttonVisibility(undefined, currentFocus ? currentFocus.filterable : undefined);

  let nbActive = 0;

  insertable && nbActive++;
  updatable && nbActive++;
  isRefreshButton && nbActive++;
  isProcessusButton && nbActive++;
  isSatelliteButton && nbActive++;

  let isOnlyOneButtonAction = nbActive === 1;

  let titleSave: string;
  let iconSave: React.ReactNode;
  const isInsertable = currentFocus?.insertable ?? true;
  const isUpdatatable = currentFocus?.updatatable ?? true;

  if (isInsertable === false && isUpdatatable === true) {
    titleSave = t("commun_sauvegarderMiseAJour");
    iconSave = (
      <span className="fa-stack">
        <Fa
          className={classNames("fa-stack-1x", isDirty ? "has-text-danger" : "has-text-link")}
          icon={["fas", "save"]}
          size="1x"
          transform="grow-3"
        />
        <Fa
          className={"fa-stack-1x has-text-black"}
          icon={["fas", "pencil"]}
          size="sm"
          transform="down-9 right-8 shrink-2"
        />
      </span>
    );
  } else if (isInsertable === true && isUpdatatable === false) {
    titleSave = t("commun_sauvegarderInsertion");
    iconSave = (
      <span className="fa-stack">
        <Fa
          className={classNames("fa-stack-1x", isDirty ? "has-text-danger" : "has-text-link")}
          icon={["fas", "save"]}
          size="1x"
          transform="grow-3"
        />
        <Fa
          className={classNames("fa-stack-1x", "has-text-black")}
          icon={["fad", "plus-circle"]}
          size="sm"
          transform="down-9 right-8 shrink-2"
          style={
            {
              "--fa-primary-color": "white",
              "--fa-secondary-color": "black"
            } as any
          }
        />
      </span>
    );
  } else {
    titleSave = t("commun_valider");
    iconSave = (
      <Fa icon={["fas", "save"]} className={isDirty ? "has-text-danger" : "has-text-link"} />
    );
  }

  // On vérifie si le trie manuel est égale au trie du focus courant
  const workingFocus = state.definitions.focus.find(
    f => f.focusId === state.definitions.selectedFocus
  );
  const focusSort: Record<string, DatatableSort> =
    workingFocus && workingFocus.sort ? parseSortStr(workingFocus.sort) : {};

  let isSameSort = true;
  Object.keys(state.userFilter.sortValues).forEach(col => {
    if (state.userFilter.sortValues[col] !== focusSort[col]) {
      isSameSort = false;
    }
  });

  return (
    <Toolbar sjmoCode={sjmoCode} tableName={tableName} tableCtrlKey={ctrlkey}>
      <Toolbar.Left>
        {insertable && (
          <Toolbar.Add selectedFocus={state.definitions.selectedFocus} onAdd={() => onAdd()} />
        )}
        <Toolbar.Item>
          <Field addons={true}>
            {updatable && (
              <Toolbar.Save
                title={titleSave}
                onSave={saveRows}
                isRounded={isOnlyOneButtonAction}
                disabled={state.state === "SAVE_PENDING"}
              >
                <span className="icon">{iconSave}</span>
              </Toolbar.Save>
            )}
            {isRefreshButton && (
              <Toolbar.Refresh onRefresh={refresh} isRounded={isOnlyOneButtonAction} />
            )}
            {isSatelliteButton && (
              <Toolbar.Satellite
                renderSatelliteMenu={(isActive: boolean, onClose: () => void, id?: string) => {
                  return (
                    <SatelliteMenu
                      tableName={tableName}
                      contextId={id}
                      query={getFilter()}
                      columns={state.definitions.columns.slice(0, 4).map(col => col.column)}
                      sjmoCode={sjmoCode}
                      isActive={isActive}
                      onClick={() => {
                        onClose();
                        track("datatable::satellite::menu::open");
                      }}
                      onMouseLeave={onClose}
                      ctrlKey={ctrlkey}
                    />
                  );
                }}
                isRounded={isOnlyOneButtonAction}
              />
            )}
            <div className="control">
              <Menu autoclose>
                <Menu.Button
                  className={classNames(
                    "button is-link is-inverted",
                    isOnlyOneButtonAction && "is-rounded"
                  )}
                >
                  <span>
                    <Trans i18nKey="commun_action" />
                  </span>
                  <Fa icon="caret-down" fixedWidth />
                </Menu.Button>
                <Menu.Content>
                  <Menu.Item className="flex">
                    <div className="flex justify-between pr-8">
                      <a
                        onClick={() => {
                          setGridPadding("small");
                          track("datatable::gridpadding", { size: "small" });
                        }}
                      >
                        <span
                          className={classNames("icon", {
                            "has-text-grey": gridPadding !== "small"
                          })}
                          title="small"
                        >
                          <Fa icon="th" aria-label="size grid small" />
                        </span>
                      </a>
                      <a
                        onClick={() => {
                          setGridPadding("large");
                          track("datatable::gridpadding", { size: "large" });
                        }}
                      >
                        <span
                          className={classNames("icon", {
                            "has-text-grey": gridPadding !== "large"
                          })}
                          title="large"
                        >
                          <Fa icon="th-large" aria-label="size grid small" />
                        </span>
                      </a>
                    </div>
                    <div>
                      <Trans i18nKey="commun_grille" />
                    </div>
                  </Menu.Item>
                  <Menu.Separator />
                  <Menu.Item
                    as="a"
                    onClick={() => {
                      toggleBreakRowDialog();
                      track("datatable::breakRow::open");
                    }}
                    aria-label="add break row"
                  >
                    <span className="icon">
                      <Fa icon="outdent" className="has-text-link" fixedWidth />
                    </span>
                    <span>
                      <Trans i18nKey="commun_ajouter_une_rupture" />
                    </span>
                  </Menu.Item>
                  {isExportExcell || isExportPdf ? <Menu.Separator /> : null}
                  {isExportExcell && (
                    <Menu.Item as="a" onClick={e => prepareExporDatatUI("XLS")}>
                      <span className="icon">
                        <Fa icon="file-excel" className="has-text-link" fixedWidth />
                      </span>
                      <span>
                        <Trans i18nKey="commun_export_excel" />
                      </span>
                    </Menu.Item>
                  )}
                  {isExportPdf && (
                    <Menu.Item as="a" onClick={e => prepareExporDatatUI("PDF")}>
                      <span className="icon">
                        <Fa icon="file-pdf" className="has-text-link" fixedWidth />
                      </span>
                      <span>
                        <Trans i18nKey="commun_export_pdf" />
                      </span>
                    </Menu.Item>
                  )}
                </Menu.Content>
              </Menu>
            </div>
          </Field>
        </Toolbar.Item>
        {isFocusButton && (
          <Toolbar.FocusMenu
            focus={state.definitions.focus}
            selectedFocus={state.definitions.selectedFocus}
            onFocusChange={onFocusChange}
            onSaveFocusPerso={onSaveFocusPerso}
            onRazFocusPerso={onRazFocusPerso}
          />
        )}
        {filterable && (
          <Toolbar.Search
            onSearch={() => {
              dispatch({ type: "TOGGLE_SEARCH" });
              track("datatable::filter::openadvanced");
            }}
          >
            <div className="level flex-col items-start p-7">
              <FilterBarComponent
                // className="is-centered"
                filters={state.definitions.filters}
                selectedFilter={state.userFilter.filterBar.filterBarId}
                startDate={state.userFilter.filterBar.startDate}
                endDate={state.userFilter.filterBar.endDate}
                selectedFilterChange={selectedFilterChange}
                startDateChange={startDateChange}
                endDateChange={endDateChange}
              />
            </div>
          </Toolbar.Search>
        )}
        <Toolbar.FilterActive
          isFilterVisible={
            Object.keys(state.userFilter.searchValues).length > 0 ||
            (Object.keys(state.userFilter.sortValues).length > 0 && !isSameSort) ||
            state.userFilter.filterBar.filterBarId !== null
          }
          onClearFilter={onClearFilter}
        />
      </Toolbar.Left>
    </Toolbar>
  );
};

const LOCAL_STORAGE_GRID_PADDING = "grid-padding";
type GridPaddingType = "small" | "large";
type GridPaddingListener = (gridPadding: GridPaddingType) => void;
let listeners: GridPaddingListener[] = [];

function useGridPadding(): [GridPaddingType, GridPaddingListener] {
  const [state, setState] = useState<GridPaddingType>(() => {
    const item: any = localStorage.getItem(LOCAL_STORAGE_GRID_PADDING);
    return item || "small";
  });

  useEffect(() => {
    function onStorageChanged(e: StorageEvent) {
      if (e.key === LOCAL_STORAGE_GRID_PADDING && e.newValue != null) {
        setState(e.newValue as GridPaddingType);
      }
    }
    window.addEventListener("storage", onStorageChanged);
    return () => {
      window.removeEventListener("storage", onStorageChanged);
    };
  }, []);

  useEffect(() => {
    function refresh(newValue: GridPaddingType) {
      setState(newValue);
    }
    listeners.push(refresh);
    return () => {
      listeners = listeners.filter(listener => listener !== refresh);
    };
  }, []);

  function changeGridPadding(gridPadding: GridPaddingType) {
    setState(() => gridPadding);
    localStorage.setItem(LOCAL_STORAGE_GRID_PADDING, gridPadding);
    listeners.forEach(listener => listener(gridPadding));
  }

  return [state, changeGridPadding];
}

const TableHeaderItemFilter: FC<{
  column: string;
  typeCompo: string;
  sysDomaineChoices: ComponentState["sysDomaineChoices"];
  filterValues: Record<string, any>;
  filterOpen: boolean;
  onFilterChange(name: string, value: any, operator?: string): void;
  onKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void;
}> = ({
  column,
  typeCompo,
  sysDomaineChoices,
  filterValues,
  filterOpen,
  onFilterChange,
  onKeyDown
}) => {
  const filterProps: FilterProps = {
    name: column,
    filterOpen: filterOpen,
    value: filterValues[column],
    onFilterChange: onFilterChange,
    onKeyDown: onKeyDown
  };

  switch (typeCompo) {
    case TypeSimpleComponent.CALENDAR:
      return <CalendarFilter {...filterProps} name={column} value={filterValues[column]} />;
    case TypeSimpleComponent.CALENDAR_HOUR:
      return <CalendarHourFilter {...filterProps} name={column} value={filterValues[column]} />;
    case TypeSimpleComponent.CHECKBOX:
      return <CheckboxFilter {...filterProps} />;
    case TypeSimpleComponent.SYS_DOMAINE:
      return <SelectFilter {...filterProps} options={sysDomaineChoices} />;
    case TypeSimpleComponent.OEL:
      return <OelFilter />;
    case TypeSimpleComponent.AUTO_COMPLETE:
      return <AutocompleteFilter {...filterProps} name={column} value={filterValues[column]} />;
    default:
      return <InputFilter {...filterProps} />;
  }
};

function buttonVisibility(toolbarValue?: boolean, focusValue?: boolean) {
  if (toolbarValue !== undefined) {
    return toolbarValue;
  }
  return focusValue !== undefined ? focusValue : true;
}

function getRSQLFilter(
  interactions: Record<string, any>,
  searchValues: Record<string, string>,
  sortValues: Record<string, DatatableSort>,
  breakColumns: string[],
  additionnalClause: RSQLCriteria | undefined,
  columns: Record<string, ComponentState>
): RSQLCriteria {
  const searchFields = Object.keys(searchValues);

  const criteriaList: GS[] = [];
  for (let field of searchFields) {
    const col = columns[field.split(".")[0]];
    if (!col) continue;

    const searchValue = searchValues[field];

    let value: any;
    if (
      [
        TypeSimpleComponent.INPUT,
        TypeSimpleComponent.INPUT_MASK,
        TypeSimpleComponent.EDITOR
      ].includes(col.typeCompo) &&
      searchValue
    ) {
      if (searchValue.includes("%")) {
        value = searchValue.replace(/%/g, "*");
      } else {
        value = `%${searchValue}%`;
      }
    } else {
      value = searchValue;
    }

    if (col.typeCompo === TypeSimpleComponent.AUTO_COMPLETE) {
      const correctedNode = GSBuilder.visit<GS>(col.additionalClause, {
        And: (node, visitor) => {
          let result: GS[] = [];
          for (let n of node.nodes) {
            let visitedNode = GSBuilder.visit(n, visitor);
            if (visitedNode) result.push(visitedNode);
          }
          return GSBuilder.AND(...result);
        },
        Or: (node, visitor) => {
          let result: GS[] = [];
          for (let n of node.nodes) {
            let visitedNode = GSBuilder.visit(n, visitor);
            if (visitedNode) result.push(visitedNode);
          }
          return GSBuilder.OR(...result);
        },
        Comparison: param => {
          return {
            ...param,
            field: `${field}.${param.field}`,
            value:
              param.value !== "" && param.value !== undefined && param.value !== null
                ? param.value
                : convertTermByOperator(value, param.operator)
          };
        }
      });
      if (correctedNode) criteriaList.push(correctedNode);
    } else if (value !== null && value !== undefined && value !== "") {
      criteriaList.push(GSBuilder.Comparison(field, "OPER_EQ", value));
    }
  }

  // on transforme l'interaction en rsql
  const rsql = mapToRSQL(interactions, sortValues, breakColumns);

  // on ajoute les filters à notre rsql
  const filtered = GSBuilder.toFilter(GSBuilder.AND(...criteriaList));
  rsql.filters.and(filtered);

  if (additionnalClause) {
    rsql.and(additionnalClause);
  }

  return rsql;
}

function mergeDatatableData(
  start: string[],
  entities: Record<string, string | "LOADING_POJO" | undefined>,
  last: string[],
  cache: Record<string, Pojo>
): Record<string, Pojo | "LOADING_POJO" | undefined> {
  let map = {};
  let indexNewEntities = 0;
  for (; indexNewEntities < start.length; indexNewEntities++) {
    map[indexNewEntities] = cache[start[indexNewEntities]];
  }

  const indexes = Object.keys(entities);
  let validMapIndex = -1;
  for (let index of indexes) {
    validMapIndex = indexNewEntities + parseInt(index, 10);
    let potentialID = entities[index];
    if (potentialID !== undefined) {
      map[validMapIndex] = cache[potentialID] || potentialID;
      // entities[index] !== "LOADING_POJO" ? cache[entities[index]] : entities[index];
    }
  }

  // gestion des entités ajoutés à la fin
  let indexEnd = Object.keys(map).length;

  for (let index = 0; index < last.length; index++) {
    map[indexEnd + index] = cache[last[index]];
  }

  return map;
}

async function loadDatatableDefinition(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string
): Promise<[DatatableFocusState[], ComponentState[]]> {
  try {
    const focusRes = await api.getFocus(sjmoCode, tableName, ctrlKey);

    let columns: ComponentState[] = [];
    if (focusRes.data.length > 0) {
      const columnRes = await api.getColumn(sjmoCode, focusRes.data[0].focusId);
      columns = columnRes.data;
    }

    return [focusRes.data as DatatableFocusState[], columns];
  } catch (e) {
    return [[], []];
  }
}

const DatatableComponents: FC<{
  rowIndex: number;
  columnIndex: number;
  column: ComponentState;
  entity: Pojo;
}> = React.memo(({ rowIndex, columnIndex, column, entity }) => {
  const {
    sjmoCode,
    tableName,
    ctrlKey,
    onChange: onChangeParent,
    onBlur,
    onContextMenu,
    onKeyDown: onKeyDownParent,
    gridPadding,
    wviState,
    readonly
  } = useContext(DatatableContext);

  const isGloballyReadonly = getReadonlyValue(readonly, entity.version);
  const isReadonly =
    isGloballyReadonly === true
      ? isGloballyReadonly
      : getReadonlyValue(column.readOnly, entity.version);

  const columnName = column.column;
  const entityId = entity.id || entity.uuid;
  const value = get(entity, [columnName], "");

  function onChange(e: SyntheticEvent<any>) {
    if (!isGloballyReadonly) onChangeParent(entityId, columnName, convertValue(e));
  }

  function onItemChange(item: Pojo | null) {
    if (!isGloballyReadonly) onChangeParent(entityId, columnName, item ? item.id : null);
  }

  function onValueChange(val: string) {
    if (!isGloballyReadonly) onChangeParent(entityId, columnName, val);
  }

  function onKeyDown(e: KeyboardEvent<any>) {
    onKeyDownParent(rowIndex, columnIndex, e);
  }

  const componentID = `${columnName}--${entityId}`;

  const navigationParams = {
    "data-navigation-key": ctrlKey,
    "data-navigation-row": rowIndex,
    "data-navigation-column": columnIndex
  };

  const size = gridPadding === "small" ? "small" : undefined;
  const isDisabled = getReadonlyValue(column.disabled, entity.version);
  const oelStyle = entity._style ? entity._style[column.column] : ({} as any);
  const wviStateCol = get(wviState, [entityId, column.column]);
  const options = getOptionsByType(column);

  switch (column.typeCompo) {
    case TypeSimpleComponent.INPUT:
      return (
        <Input
          id={componentID}
          name={columnName}
          value={value}
          onChange={onChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          onKeyDown={onKeyDown}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.INPUT_NUMBER:
      return (
        <InputNumber
          id={componentID}
          name={columnName}
          value={value}
          onChange={onChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          onKeyDown={onKeyDown}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.INPUT_MASK:
      return (
        <InputMask
          id={componentID}
          name={columnName}
          value={value}
          onChange={onChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          onKeyDown={onKeyDown}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.CALENDAR:
      return (
        <Calendar
          id={componentID}
          name={columnName}
          value={value}
          onChange={onChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          onKeyDown={onKeyDown}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.CALENDAR_HOUR:
      return (
        <CalendarHours
          id={componentID}
          name={columnName}
          value={value}
          onChange={onChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          onKeyDown={onKeyDown}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.CHECKBOX:
      return (
        <div className="control">
          <Checkbox.Only
            id={componentID}
            name={columnName}
            value={value}
            onValueChange={(field: string, val: any) => onValueChange(val)}
            onBlur={() => onBlur(entityId, columnName)}
            onContextMenu={(e: any) => onContextMenu(entityId, columnName, e)}
            onKeyDown={onKeyDown}
            size={size}
            readOnly={isReadonly}
            disabled={isDisabled}
            style={oelStyle}
            {...navigationParams}
            {...options}
          />
        </div>
      );

    case TypeSimpleComponent.SYS_DOMAINE:
      return (
        <Select
          id={componentID}
          name={columnName}
          value={value}
          onChange={onChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          sysDomaineChoices={column.sysDomaineChoices || []}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          wviState={wviStateCol}
          required={column.mandatory}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.EDITOR:
      return (
        <DatatableEditor
          name={columnName}
          value={get(entity, [columnName], null)}
          onValueChange={onValueChange}
          onBlur={() => onBlur(entityId, columnName)}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          readonly={isReadonly}
          disabled={isDisabled}
          style={oelStyle}
          {...options}
        />
      );

    case TypeSimpleComponent.AUTO_COMPLETE:
      return (
        <DatatableAutocomplete
          entity={entity}
          tableName={tableName}
          sjmoCode={sjmoCode}
          id={componentID}
          name={column.column}
          value={get(entity, [columnName], null)}
          onItemChange={onItemChange}
          onBlur={() => onBlur(entityId, columnName)}
          onKeyDown={onKeyDown}
          onContextMenu={e => onContextMenu(entityId, columnName, e)}
          joinTableName={column.joinTableName}
          joinListFields={column.joinListFields}
          additionalClause={column.additionalClause}
          sortClause={column.sortClause}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          controlProps={{ expanded: true }}
          parent={{
            tableName,
            ctrlKey,
            entityId
          }}
          style={{ width: "100%" }}
          styleInput={oelStyle}
          hasLov={column.hasLov}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.AUTO_COMPLETE_VIEW:
      return (
        <DatatableAutocompleteView
          entity={entity}
          tableName={tableName}
          sjmoCode={sjmoCode}
          id={componentID}
          name={column.column}
          value={get(entity, [columnName], null)}
          onItemChange={onItemChange}
          onBlur={() => onBlur(entityId, columnName)}
          onKeyDown={onKeyDown}
          onContextMenu={e => onContextMenu(entityId, columnName, e, true)}
          joinTableName={column.joinTableName}
          joinListFields={column.joinListFields}
          additionalClause={column.additionalClause}
          sortClause={column.sortClause}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          controlProps={{ expanded: true }}
          parent={{
            tableName,
            ctrlKey,
            entityId
          }}
          style={{ width: "100%" }}
          styleInput={oelStyle}
          hasLov={column.hasLov}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.AUTO_COMPLETE_VIEW_FREE:
      return (
        <DatatableAutocompleteViewFree
          entity={entity}
          tableName={tableName}
          sjmoCode={sjmoCode}
          id={componentID}
          name={column.column}
          value={get(entity, [columnName], null)}
          onItemChange={onItemChange}
          onBlur={() => onBlur(entityId, columnName)}
          onKeyDown={onKeyDown}
          onContextMenu={e => onContextMenu(entityId, columnName, e, true)}
          joinTableName={column.joinTableName}
          joinListFields={column.joinListFields}
          additionalClause={column.additionalClause}
          sortClause={column.sortClause}
          size={size}
          readOnly={isReadonly}
          disabled={isDisabled}
          controlProps={{ expanded: true }}
          parent={{
            tableName,
            ctrlKey,
            entityId
          }}
          style={{ width: "100%" }}
          styleInput={oelStyle}
          hasLov={column.hasLov}
          wviState={wviStateCol}
          {...navigationParams}
          {...options}
        />
      );

    case TypeSimpleComponent.OEL:
      return (
        <Oel
          id={componentID}
          name={columnName}
          value={value}
          onContextMenu={e => onContextMenu(entityId, columnName, e, true)}
          {...options}
        />
      );

    default:
      return <div>{value}</div>;
  }
});

function executeNavigationMove(tableCtrlKey: string, rowIndex: number, colIndex: number) {
  const element = document.querySelector<HTMLInputElement>(
    `[data-navigation-key='${tableCtrlKey}'][data-navigation-row='${rowIndex}'][data-navigation-column='${colIndex}']`
  );

  if (element) {
    element.focus();
  }
}

const DatatableAutocomplete: FC<AutocompleteProps & { entity: Pojo }> = ({ entity, ...props }) => {
  return (
    <DataInteractionContext.Provider value={entity}>
      <Autocomplete {...props} />
    </DataInteractionContext.Provider>
  );
};

const DatatableAutocompleteView: FC<AutocompleteProps & { entity: Pojo }> = ({
  entity,
  ...props
}) => {
  return (
    <DataInteractionContext.Provider value={entity}>
      <AutocompleteView {...props} />
    </DataInteractionContext.Provider>
  );
};

const DatatableAutocompleteViewFree: FC<AutocompleteProps & { entity: Pojo }> = ({
  entity,
  ...props
}) => {
  return (
    <DataInteractionContext.Provider value={entity}>
      <AutocompleteViewFree {...props} />
    </DataInteractionContext.Provider>
  );
};
