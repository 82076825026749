import React, { useEffect, useMemo } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import Scrollbars from "react-custom-scrollbars";
import { fetchArticulationData } from "api";

import ProcessusMenu from "../processus/ProcessusMenu";
import TabHeader from "./TabHeader";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { tw } from "twind";
import { useTranslation } from "react-i18next";
import { useInfiniteQuerySatellite } from "hooks/useInfiniteQuerySatellite";
import InfiniteList from "containers/listegenerique/InfiniteList";

export interface Articulation {
  type: "ORIG" | "CSQ";
  id: number;
  table: string;
  tableLibelle: string;
  entityId: string;
  title: string;
  values: string[];
  commentaire: string;
}

function TabArticulation(props: TabSatelliteProps) {
  const { t } = useTranslation();

  const loadMoreButtonRef = React.useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    props.countAction(props.tableName, props.contextId);
    // il veut [props], c'est pas ce qu'on veut
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableName, props.contextId]);

  const {
    data: articulations,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuerySatellite(
    "articulation",
    props.tableName,
    props.contextId,
    fetchArticulationData
  );

  const { origList, csqList } = useMemo(() => {
    const origList: { data: any[]; meta: any }[] = [];
    const csqList: { data: any[]; meta: any }[] = [];
    articulations?.pages.forEach(p => {
      origList.push({ data: p.data.filter((it: any) => it.type === "ORIG"), meta: {} });
      csqList.push({ data: p.data.filter((it: any) => it.type === "CSQ"), meta: {} });
    });
    return { origList, csqList };
  }, [articulations?.pages]);

  return (
    <>
      <TabHeader
        i18nKey="commun_articulations_liees"
        count={props.count}
        tableName={props.tableName}
        contextId={props.contextId}
        sjmoCode={props.sjmoCode}
      />
      {origList && csqList && (
        <div className={tw("flex w-full gap-3")}>
          <div className={tw("flex-grow")}>
            <p className={tw("text-center font-semibold")}>{t("commun_articulationCsq")}</p>
            <Scrollbars autoHide style={{ height: props.height }}>
              <InfiniteList
                data={csqList.length > 0 ? csqList : undefined}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                loadMoreButtonRef={loadMoreButtonRef}
                fetchNextPage={fetchNextPage}
                renderCell={pojo => (
                  <ArticulationCard
                    key={pojo.id}
                    articulation={pojo as any}
                    sjmoCode={props.sjmoCode}
                  />
                )}
                listHeight={props.height as number}
              />
            </Scrollbars>
          </div>
          <div className={tw("flex-grow")}>
            <p className={tw("text-center font-semibold")}>{t("commun_articulationOrig")}</p>
            <Scrollbars autoHide style={{ height: props.height }}>
              <InfiniteList
                data={origList.length > 0 ? origList : undefined}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                loadMoreButtonRef={loadMoreButtonRef}
                fetchNextPage={fetchNextPage}
                renderCell={pojo => (
                  <ArticulationCard
                    key={pojo.id}
                    articulation={pojo as any}
                    sjmoCode={props.sjmoCode}
                  />
                )}
                listHeight={props.height as number}
              />
            </Scrollbars>
          </div>
        </div>
      )}
    </>
  );
}

function ArticulationCard({
  sjmoCode,
  articulation
}: {
  sjmoCode: string;
  articulation: Articulation;
}) {
  return (
    <div className={tw("py-3 px-2 my-3 bg-white rounded-md shadow border border-gray-50")}>
      <ProcessusProvider
        sjmoCode={sjmoCode}
        tableName={articulation.table}
        selected={articulation.entityId}
      >
        <ProcessusMenu isAnchor isRight={false} color="link">
          {articulation.tableLibelle}, ({articulation.values})
        </ProcessusMenu>
      </ProcessusProvider>

      <p className={tw("font-semibold my-1")}>{articulation.title}</p>
      <p>{articulation.commentaire}</p>
    </div>
  );
}

export default TabArticulation;
