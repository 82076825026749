import React, {
  FC,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
  useRef,
  useReducer
} from "react";
import * as ReachCB from "@reach/combobox";

import classNames from "classnames";
import produce from "immer";
import axios, { CancelTokenSource } from "axios";
import { useDispatch } from "react-redux";
import { RSQLFilterExpression, Operators, RSQLFilterList } from "rsql-criteria-typescript";

import { Pojo } from "types/Galaxy";
import { Fa } from "composants/Icon";
import { getSizeClasses, getColorsClasses } from "composants/common";

import Control from "composants/form/Control";

import { initRsqlCriteria, GS, GSBuilder } from "utils/query.utils";

import { contextualize } from "actions/contextTreeModule";
import { ButtonNoStyle } from "composants/button";
import { Action } from "redux";
import { ActionTypeData } from "reducers/Action";
import { useTranslation } from "react-i18next";
import { findView, findOneView } from "api/entities";

import "@reach/combobox/styles.css";
import "../autocomplete/Autocomplete.css";
import { uuidv4 } from "utils/uuid.utils";
import useInteractions from "hooks/useInteractions";
import get from "lodash-es/get";
import toaster from "composants/toaster/toaster";
import { NotificationGroup } from "types/Notification";
import { AutocompleteProps } from "composants/autocomplete/AutoComplete";

const {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
  ComboboxButton
} = ReachCB;

const PAGE_SIZE = 50;

function isNullish(value: any) {
  return value === null || value === undefined;
}

const additionalClause = GSBuilder.Comparison("search", "OPER_LIKE_ANYWHERE", "");

const AutocompleteViewFree: FC<AutocompleteProps & {
  openOnFocus?: boolean;
  lefIcon?: React.ReactNode;
}> = props => {
  const [t] = useTranslation();
  const [state, setState] = useState<"ON_MOUNT" | "IDLE">("ON_MOUNT");
  const [currentEntity, setCurrentEntity] = useState<Pojo | null>();
  const [list, dispatch] = useReducer(reducerData, []);
  const [term, setTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const reduxDispatch = useDispatch();
  const optionClickedRef = useRef(false);
  const buttonClickedRef = useRef(false);
  const popOverRef = useRef<HTMLElement | null>(null);

  // Les navigateurs propose tous seul de l'autocomplete sur les fields
  // Pour ignorer ça on devrait mettre [autocomplete = "off"]
  // Mais les navigateurs ignore cette dernière depuis peu
  // Alors on truande le système de la manière suivante
  // --------------------------------------------------
  // Lorsqu'un name est présent dans l'input,
  // il est préférable d'utiliser un uuid
  // Si on a pas de name a positionner
  // il vaut mieux mettre "off"
  const autocompleteKiller = useMemo(() => {
    if (props.autocomplete) {
      return props.autocomplete;
    } else {
      return `${props.id}-${new Date().toISOString()}`;
    }
  }, [props]);
  // On refresh sur props en entier afin que plusieurs recherches succéssives, sans refresh du composant,
  // ne déclenchent pas l'autocomplete de google

  const interactionsGS = useInteractions({
    type: "dataPreferred",
    sjmoCode: props.sjmoCode,
    ctrlKey: (props.name || props.id) ?? "<unknown>",
    tableName: props.tableName
  });

  const loadSelectedEntity = useCallback(
    function loadSelectedEntity(source?: CancelTokenSource) {
      const interactions = props.forceInteractionOff !== true ? interactionsGS : undefined;

      let rsql = initRsqlCriteria();
      if (interactions) {
        const keys = Object.keys(interactions);
        for (let key of keys) {
          rsql.filters.and(new RSQLFilterExpression(key, Operators.Like, interactions[key]));
        }
      }
      if (props.additionalClause) {
        rsql.filters.and(GSBuilder.toFilter(props.additionalClause));
      }

      if (props.joinTableName && !isNullish(props.value)) {
        let rsqlId = initRsqlCriteria();
        rsqlId.filters.and(new RSQLFilterExpression("id", Operators.Like, props.value));
        findView(
          {
            tableName: props.joinTableName,
            sjmoCode: props.sjmoCode,
            labelDetailsContext: props.joinListFields,
            filter: rsqlId ? rsqlId.build() + "&" : "",
            first: 0,
            size: 1
          },
          source
        )
          .then(res => {
            if (res.data && res.data.data.length > 0) {
              setCurrentEntity(res.data.data[0]);
              setTerm(res.data.data[0].label);
            } else {
              setCurrentEntity({ id: props.value, label: props.value } as any);
              setTerm(props.value);
            }
          })
          .catch(e => {
            if (!axios.isCancel(e)) {
              console.error("error during the fetch of the entity with id=", props.value);
              console.error(e);
            }
          });
      } else if (isNullish(props.value)) {
        setCurrentEntity(null);
        setTerm("");
      }

      setState("IDLE");
    },
    [
      interactionsGS,
      props.additionalClause,
      props.forceInteractionOff,
      props.includeOelStyle,
      props.joinListFields,
      props.joinTableName,
      props.sjmoCode,
      props.value
    ]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    loadSelectedEntity(source);
    return () => {
      source.cancel();
    };
  }, [loadSelectedEntity]);

  const contextualizeCtrlKey = props.name ?? props.id ?? "<unknown>";
  useEffect(() => {
    const cancelTasks = reduxDispatch(
      contextualize(props.sjmoCode, props.tableName || null, contextualizeCtrlKey, null)
    ) as any;

    return () => {
      cancelTasks();
    };
  }, [currentEntity, reduxDispatch, contextualizeCtrlKey, props.sjmoCode, props.tableName]);

  const fetchData = useCallback(
    async function fetchData(
      term?: string,
      reset = true,
      first = 0,
      size = PAGE_SIZE,
      cancelToken?: CancelTokenSource
    ) {
      if (reset) {
        setPage(0);
      }

      const interactions = props.forceInteractionOff !== true ? interactionsGS : undefined;
      if (!(additionalClause && props.joinTableName)) {
        return Promise.resolve();
      }
      let rsqlFilter: RSQLFilterList | null = null;
      if (term) {
        // on transforme la requête en RSQL avec les valeurs de l'input.
        rsqlFilter = GSBuilder.toFilter(additionalClause, term, true);
      } else {
        // on garde seulement les critères suivant pour un find ALL:
        // - OPER_NULL
        // - OPER_NOT_NULL
        // - les critères de recherche avec des valeurs prédéfinies
        const filteredNull = GSBuilder.visit<GS>(additionalClause, {
          And: (node, visitor) => {
            let result: GS[] = [];
            for (let n of node.nodes) {
              let visitedNode = GSBuilder.visit(n, visitor);
              if (visitedNode) result.push(visitedNode);
            }
            return GSBuilder.AND(...result);
          },
          Or: (node, visitor) => {
            let result: GS[] = [];
            for (let n of node.nodes) {
              let visitedNode = GSBuilder.visit(n, visitor);
              if (visitedNode) result.push(visitedNode);
            }
            return GSBuilder.OR(...result);
          },
          Comparison: node => {
            if (
              node.operator === "OPER_NULL" ||
              node.operator === "OPER_NOT_NULL" ||
              (node.value !== undefined && node.value !== null && node.value !== "")
            )
              return node;
            return null as any;
          }
        });
        if (filteredNull) rsqlFilter = GSBuilder.toFilter(filteredNull, undefined, true);
      }

      const rsql = initRsqlCriteria();
      if (interactions) {
        const keys = Object.keys(interactions);

        for (let key of keys) {
          rsql.filters.and(new RSQLFilterExpression(key, Operators.Like, interactions[key]));
        }
      }
      if (rsqlFilter !== null) rsql.filters.and(rsqlFilter);
      if (props.additionalClause) {
        rsql.filters.and(GSBuilder.toFilter(props.additionalClause));
      }

      if (props.sortClause) {
        for (let s of props.sortClause) {
          if (s.sortOrder !== "UNSORTED") {
            rsql.orderBy.add(s.sortField, s.sortOrder === "ASCENDING" ? "asc" : "desc");
          }
        }
      }

      try {
        const res = await findView(
          {
            tableName: props.joinTableName,
            sjmoCode: props.sjmoCode,
            labelDetailsContext: props.joinListFields,
            filter: rsql ? rsql.build() + "&" : "",
            first,
            size
          },
          cancelToken
        );

        if (reset) {
          dispatch({ type: "REPLACE", payload: res.data.data });
        } else {
          dispatch({ type: "APPEND", payload: { first, size, pojos: res.data.data } });
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          dispatch({ type: "EMPTY" });
        }
      }
    },
    [
      interactionsGS,
      additionalClause,
      props.joinListFields,
      props.joinTableName,
      props.sjmoCode,
      props.sortClause
    ]
  );

  useLayoutEffect(() => {
    setTerm(currentEntity != null ? currentEntity.label ?? currentEntity.id : "");
  }, [currentEntity]);

  function onBlur(e: React.FocusEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;

    requestAnimationFrame(() => {
      if (optionClickedRef.current === true) {
        optionClickedRef.current = false;
        return;
      }

      if (buttonClickedRef.current === true) {
        buttonClickedRef.current = false;
        setIsOpen(old => !old);
        return;
      }

      if (value === "" && props.value && currentEntity) {
        setTerm(currentEntity.label);
      } else if (
        props.joinTableName &&
        (currentEntity === null || (currentEntity !== null && currentEntity?.label !== term))
      ) {
        if (term === "") {
          setCurrentEntity(null);
          setIsOpen(false);
          if (props.value !== null) {
            props.onItemChange(null, props.name);
          }
        } else {
          const interactions = props.forceInteractionOff !== true ? interactionsGS : undefined;
          let rsql = initRsqlCriteria();
          if (interactions) {
            const keys = Object.keys(interactions);
            for (let key of keys) {
              rsql.filters.and(new RSQLFilterExpression(key, Operators.Like, interactions[key]));
            }
          }
          if (props.additionalClause) {
            rsql.filters.and(GSBuilder.toFilter(props.additionalClause));
            console.log(rsql.build());
          }

          let rsqlId = initRsqlCriteria();
          rsqlId.filters.and(new RSQLFilterExpression("id", Operators.Like, props.value));
          findView({
            tableName: props.joinTableName,
            sjmoCode: props.sjmoCode,
            labelDetailsContext: props.joinListFields,
            filter: rsqlId ? rsqlId.build() + "&" : "",
            first: 0,
            size: 1
          })
            .then(res => {
              setIsOpen(false);
              if ((res.data as any) !== "" && get(res.data, ["id"], null) !== props.value) {
                setCurrentEntity(res.data.data[0]);
                props.onItemChange(res.data.data[0], props.name);
              } else {
                setCurrentEntity({ id: value, label: value } as Pojo);
                props.onItemChange({ id: value, label: value } as Pojo, props.name);
              }
            })
            .catch(e => {
              if (currentEntity != null) {
                setTerm(currentEntity.label);
              } else {
                setTerm("");
                setCurrentEntity(null);
              }
              setIsOpen(false);
              console.error("error during the auto fetch of the entity with id=", value);
              console.error(e);
            });
        }
      }
    });
  }

  useEffect(() => {
    const cancel = axios.CancelToken.source();
    let timer: NodeJS.Timeout;
    if (props.joinTableName && isOpen) {
      timer = setTimeout(() => {
        fetchData(term, true, 0, PAGE_SIZE, cancel);
      }, 200);
    }
    return () => {
      timer && clearTimeout(timer);
      cancel.cancel();
    };
  }, [props.joinTableName, isOpen, fetchData, term]);

  function onSelectItem(label: string) {
    const item = list.find(el => el && (el.labelDetails === label || el.label === label));
    // si on a undefined, on a un BUG
    if (item !== undefined) {
      setCurrentEntity(item);
      props.onItemChange(item, props.name);
      setIsOpen(false);
    }
  }

  function onFocus(e: React.FocusEvent<HTMLInputElement>) {
    props.onFocus && props.onFocus(e);
    if (!e.isDefaultPrevented() && props.openOnFocus) {
      fetchData();
    }
  }

  function onChange(e: React.FormEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length > 0) {
      setIsOpen(true);
    }
    let value = e.currentTarget.value;
    if (term === "") value = value.trim();
    setTerm(value);
  }

  function onClose() {
    if (!(props.readOnly || props.disabled)) {
      setCurrentEntity(null);
      props.onItemChange(null, props.name);
    }
  }

  function toggleSearch() {
    if (!(props.readOnly || props.disabled)) {
      setIsOpen(true);
      fetchData();
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    props.onKeyDown && props.onKeyDown(e);

    if (!e.isDefaultPrevented() && e.key === "ArrowDown" && !isOpen) {
      setIsOpen(true);
      fetchData(term);
    }

    ajustScrollPopover();
  }

  function ajustScrollPopover() {
    let container = popOverRef.current;
    if (!container) return;

    const element = container.querySelector<HTMLElement>("[aria-selected=true]");

    // on set le scroll en fonction de l'offsetTop de l'élément que l'on a et de l'offset top de son conteneur.
    // ce qui nous permet de suivre l'élément au fur et à mesure que l'élément sélectionné (via `aria-selected`)
    // change de position (haut / bas).
    if (element) container.scrollTop = element.offsetTop - container.offsetTop;
  }

  function onDoubleClick() {
    if (
      props.parent &&
      props.parent.ctrlKey &&
      props.joinTableName &&
      props.id &&
      !props.readOnly &&
      props.hasLov
    ) {
      // Désactivé tant que le ticket #1250 n'est pas fait.
      toaster.notify({
        id: uuidv4(),
        group: NotificationGroup.DEFAULT,
        title: "LOV unitaire désactivé",
        createdAt: new Date().toISOString(),
        intent: "INFO",
        priority: "NORMAL"
      });
    }
  }

  let termIsLabelEntity =
    currentEntity && (term === currentEntity.labelDetails || term === currentEntity.label);
  useEffect(() => {
    if (page >= 1 && page * PAGE_SIZE >= list.length) {
      fetchData(termIsLabelEntity ? undefined : term, false, page * PAGE_SIZE, PAGE_SIZE);
    }
  }, [fetchData, page, term, list, termIsLabelEntity]);

  if (state === "ON_MOUNT") {
    return null;
  }

  return (
    <Combobox
      className="field has-addons"
      style={props.style}
      onSelect={onSelectItem}
      openOnFocus={props.openOnFocus}
    >
      <Control
        className="control"
        iconsLeft={props.lefIcon}
        iconsRight={
          currentEntity != null ? (
            <ButtonNoStyle
              className={classNames(
                "icon is-small is-right cursor-pointer",
                !(props.readOnly || props.disabled) && "has-text-link"
              )}
              onClick={onClose}
            >
              <Fa icon="times" />
            </ButtonNoStyle>
          ) : (
            <ComboboxButton
              as={ButtonNoStyle}
              className={classNames(
                "icon is-small is-right cursor-pointer",
                !(props.readOnly || props.disabled) && "has-text-link"
              )}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Enter" || e.key === " ") {
                  buttonClickedRef.current = true;
                }
              }}
              onClick={toggleSearch}
            >
              <Fa icon="caret-down" />
            </ComboboxButton>
          )
        }
        {...props.controlProps}
      >
        <ComboboxInput
          className={classNames(
            "input",
            props.className,
            getSizeClasses(props.size),
            getColorsClasses(props.wviState || "")
          )}
          name={autocompleteKiller}
          placeholder={props.placeholder}
          autocomplete={false}
          autoComplete={autocompleteKiller}
          selectOnClick
          style={props.styleInput}
          value={term}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onContextMenu={props.onContextMenu}
          onDoubleClick={onDoubleClick}
          readOnly={props.readOnly}
          disabled={props.disabled}
          title={props.tooltip}
          data-navigation-row={props["data-navigation-row"]}
          data-navigation-column={props["data-navigation-column"]}
          data-navigation-key={props["data-navigation-key"]}
        />
      </Control>
      {(list.length > 0 || term.length > 0) && (
        <ComboboxPopover
          ref={popOverRef as any}
          className="is-paddingless autocomplete-list-container"
          style={{
            maxHeight: 300,
            overflowY: "auto",
            zIndex: 50,
            display: !isOpen ? "none" : undefined
          }}
          onMouseDown={() => {
            optionClickedRef.current = true;
          }}
        >
          <ComboboxList>
            {list.map(
              el =>
                el && (
                  <ComboboxOption
                    key={el.id}
                    value={el.labelDetails || el.label}
                    onMouseDown={() => {
                      optionClickedRef.current = true;
                    }}
                  />
                )
            )}
          </ComboboxList>
          <button
            tabIndex={-1}
            className="button is-text is-small is-fullwidth"
            onMouseDown={() => {
              optionClickedRef.current = true;
            }}
            onClick={() => setPage(old => old + 1)}
          >
            {t("commun_charger_plus")}
          </button>
        </ComboboxPopover>
      )}
    </Combobox>
  );
};

export default AutocompleteViewFree;

type ActionAllReducerData =
  | ActionTypeData<"REPLACE", Pojo[]>
  | ActionTypeData<"APPEND", { pojos: Pojo[]; first: number; size: number }>
  | Action<"EMPTY">;

function reducerData(state: Pojo[], action: ActionAllReducerData) {
  switch (action.type) {
    case "REPLACE":
      return action.payload;

    case "APPEND": {
      const { first, size, pojos } = action.payload;
      return produce(state, draft => {
        for (
          let index = first, last = first + size, dataIndex = 0;
          index < last;
          index++, dataIndex++
        ) {
          draft[index] = pojos[dataIndex];
        }
      });
    }

    case "EMPTY":
      return [];

    default:
      return state;
  }
}
