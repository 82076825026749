import { FlatTreeNode, RoughTreeData } from "types/Tree";
import { visitor } from "utils/tree.utils";

type VisitorType<T> = Array<T> | T;

/**
 * Génère une structure en arbre à partir des données flat
 *
 * Les données reçues sont sous forme flat, exemple :
 *
 *  [ {
 *   "id" : 261,
 *   "parent" : null,
 *   "content" : "Administratif",
 *   "infosCompl" : null,
 *   "expanded" : null
 * }, {
 *   "id" : 262,
 *   "parent" : 261,
 *   "content" : "Général",
 *   "infosCompl" : null,
 *   "expanded" : null
 * }]
 *
 * Il faut les convertir en arbre pour la librairie reac-sorting-tree, exemple :
 * [
 *  {
 *    id: 261,
 *    infosCompl: null,
 *    title: 'Administratif',
 *    expanded: false,
 *    children: [
 *      {
 *        id: 262,
 *        infosCompl: null,
 *        title: 'Général',
 *        expanded: false,
 *        children: []
 *      }
 *    ]
 *  }
 * ]
 *
 * @param {!Object[]} flatData
 * @param {!function=} getKey - Fonction qui retourne l'id depuis une flatData
 * @param {!function=} getParentKey - Fonction qui retourne l'id du parent depuis flatData
 * @param {string|number=} rootKey - L'id de la root à partir duquel on construit l'arbre
 *                                   (undefined s'il n'y a pas de root définie)
 * @return {Object[]} treeData - The flat data represented as a tree
 */
export function getTreeFromFlatData(
  flatData: FlatTreeNode[],
  getKey: (node: FlatTreeNode) => string | number | undefined,
  getParentKey: (node: FlatTreeNode) => string | number | undefined,
  rootKey: string | number | undefined
): RoughTreeData[] {
  if (!flatData) {
    return [];
  }

  if (!rootKey) {
    rootKey = "";
  }
  const childrenToParents: { [key: string]: FlatTreeNode[] } = {};

  for (let child of flatData) {
    let parentKey = getParentKey(child);
    parentKey = parentKey ? parentKey : "";

    const alreadyIn = childrenToParents[parentKey]
      ? childrenToParents[parentKey].findIndex(node => node.key === child.key) > -1
      : false;

    if (parentKey in childrenToParents && !alreadyIn) {
      childrenToParents[parentKey].push(child);
    } else if (!alreadyIn) {
      childrenToParents[parentKey] = [child];
    }
  }

  if (!(rootKey in childrenToParents) && !("" in childrenToParents)) {
    return [];
  } else if (!(rootKey in childrenToParents) || "" in childrenToParents) {
    rootKey = "";
  }

  const trav = (parent: FlatTreeNode): RoughTreeData => {
    const parentKey = getKey(parent) as string | number;

    const node = {
      ...parent,
      id: parent.id,
      expanded: parent.expanded ? parent.expanded : false
    };

    if (parentKey in childrenToParents) {
      return {
        ...node,
        children: childrenToParents[parentKey].map((child: FlatTreeNode) => trav(child))
      };
    }

    return { ...(node as any) };
  };

  return childrenToParents[rootKey].map(trav);
}

/**
 * Converti un arbre en données flat.
 * Cette convertion est éxactement inverse à celle de getTreeFromFlatData.
 * Voir cette dernière pour un exemple de formation des données.
 *
 * @export
 * @param {({
 *   treeData: RoughTreeData[];
 *                     L'arbre d'entré
 *   getKey: (node: FlatTreeNode) => string | number;
 *                     La méthode permettant d'obtenir un id à partir d'une node
 *   ignoreCollapsed: boolean;
 *                     Faut-il ignorer les enfants des noeuds non-ouvert
 * })} params
 * @returns {FlatTreeNode[]}
 */
export function getFlatDataFromTree(args: {
  treeData: RoughTreeData[];
  ignoreCollapsed: boolean;
}): FlatTreeNode[] {
  const { treeData, ignoreCollapsed } = args;
  if (!treeData || treeData.length < 1) {
    return [];
  }

  const flattened: FlatTreeNode[] = [];

  visitor(
    treeData,
    (node, parent, props) => {
      const { expanded, children, toAdd, ...rest } = node;
      const flat: FlatTreeNode = {
        ...rest,
        level: node.level as number,
        expanded: node.expanded ? node.expanded : false,
        parent: parent ? parent.id : undefined
      };

      flattened.push(flat);
    },
    (node, props) => {
      if (!props.ignoreCollapsed || node.expanded) {
        return node.children;
      }
      return [];
    },
    undefined,
    { ignoreCollapsed }
  );

  return flattened;
}

interface NodeInfos {
  node: RoughTreeData;
  parentNode: RoughTreeData | null;
  path: string[];
  lowerSiblingCounts: number[];
  treeIndex: number;
}

export function getIndexFromParent(args: {
  treeData: RoughTreeData[];
  nodeTofind: RoughTreeData;
}): number {
  const { treeData, nodeTofind } = args;

  // Gestion du cas particulier où node est à la racine
  if (treeData.indexOf(nodeTofind) > -1) {
    return treeData.indexOf(nodeTofind);
  }

  // Visite de tous les noeuds de l'arbre jusqu'a trouver le bon parent
  let index: number = -1;
  visitor(
    treeData,
    node => {
      if (node.children && node.children.indexOf(nodeTofind) > -1) {
        index = node.children.indexOf(nodeTofind);
      }
    },
    node => node.children
  );

  return index;
}
