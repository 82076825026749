import { PagedResource } from "@axin-org/comet";
import { AxiosPromise } from "axios";
import { useInfiniteQuery } from "react-query";

const FETCH_SIZE = 50;

const infiniteQueryOptions = {
  staleTime: 60000,
  keepPreviousData: true,
  getNextPageParam: (lastPage: any, allPages: any) => {
    const first = allPages.length * FETCH_SIZE;
    return lastPage?.meta?.totalRecords > first ? first : false;
  }
};

export function useInfiniteQuerySatellite<T>(
  satellite: string,
  tableName: string,
  id: string | undefined,
  fetchData: (
    tableName: string,
    id: string,
    first: number,
    size: number
  ) => AxiosPromise<PagedResource<T>>
) {
  async function fetch(pageParam: number) {
    if (!id) {
      return [];
    }
    const response = await fetchData(tableName, id, pageParam, FETCH_SIZE);
    return response.data;
  }

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    ["satellite", satellite, tableName, id],
    ({ pageParam = 0 }) => (id ? fetch(pageParam) : Promise.resolve([])),
    infiniteQueryOptions
  );

  return {
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  };
}
