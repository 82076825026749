import Action from "reducers/Action";
import produce from "immer";

import {
  GET_EXPERT_TREE_FOCUS_SUCCESS,
  GET_FLAT_TREE_DATA_SUCCESS,
  TREE_DATA_CHANGE,
  TREE_EXPAND_COLLAPSE_ALL,
  ADMIN_EXPERT_TREE_MODULE,
  ADMIN_EXPERT_TREE_PANEL_ID,
  TREE_ON_CRUD_NODE_EXPERT_SUCESS,
  TREE_ON_CRUD_NODE_EXPERT_ERROR,
  ADMIN_EXPERT_TREE_CONTAINER,
  ADMIN_EXPERT_TREE_COMPONENT,
  ADMIN_EXPERT_TREE_IS_HEADER,
  ADMIN_EXPERT_TREE_DATATABLE,
  INIT_TREE_FOCUS_SUCESS,
  ON_TREE_FOCUS_CHANGE_SUCCESS,
  TREE_ON_CRUD_NODE_ERROR,
  TREE_ON_CRUD_NODE_SUCESS,
  TREE_INFO_LOADED,
  SHOULD_UPDATE_TREE
} from "constant/tree";
import { FlatTreeNode, RoughTreeData } from "types/Tree";
import { getTreeFromFlatData, getFlatDataFromTree } from "composants/tree/Utils";
import { FocusState } from "types/Focus";
import { SHOULD_NOT_UPDATE_GALAXY } from "constant/galaxy";
import { visitor, dataWithOrderToTree } from "utils/tree.utils";
import {
  GET_MINI_EXPERT_TREE_FOCUS_SUCCESS,
  ADMIN_MINI_EXPERT_TREE_MODULE,
  ADMIN_MINI_EXPERT_TREE_PANEL_ID
} from "constant/adminMiniExpert";
import {
  ADMIN_MENU_TREE_MODULE,
  ADMIN_MENU_TREE_PANEL_ID,
  GET_MENU_TREE_FOCUS_SUCCESS
} from "constant/adminMenu";

export type TreeFocus = FocusState & {
  readOnly: boolean;
  nodeType: string;
  typeAlgorithm: "UNIQUE_ID" | "SIBLINGS_BY";
};

export interface TreeFocuses {
  treeFocuses: { [id: string]: TreeFocus };
  // Object de la forme {id : TreeData}
  treeDatas: { [treeFocusId: string]: RoughTreeData[] };
  // id de l'arbre selectionné
  selectedTree: string;
  rowHeight: number;
  shouldUpdate?: boolean;
}

/**
 * Un arbre est définit par ses données.
 * Le réducer contient toutes les définitions d'arbre qui ont été chargées.
 * Ces définitions sont rangées par sjmoCode puis panelId.
 *  + Cas particulier de l'administration des expert.
 *
 * Exemple de structure :
 * {
 *   "OGCO020" : {
 *     0 : {
 *       treeFocuses : {0 : treeData, 57 : autreTreeData},
 *       selectedTree : 57
 *     },
 *     162 : {
 *       treeFocuses : {645 : encoreAutreTreeData},
 *       selectedTree : 645
 *     }
 *   },
 *   "EXPERT" : {
 *     0 : {
 *       treeFocuses : {"EXPERT" : treeData},
 *       selectedTree : "EXPERT"
 *     }
 *   },
 * }
 *
 * @export
 * @interface TreeReducerState
 */
export interface TreeReducerState {
  [sjmoCode: string]: { [panelId: string]: TreeFocuses };
}

const initialState = {};

export default function treeReducer(
  state: TreeReducerState = initialState,
  action: Action<any>
): TreeReducerState {
  switch (action.type) {
    case INIT_TREE_FOCUS_SUCESS: {
      return initTreeFocuses(state, action);
    }
    case GET_FLAT_TREE_DATA_SUCCESS: {
      return getTreeData(state, action);
    }
    case GET_EXPERT_TREE_FOCUS_SUCCESS: {
      return initExpertTreeData(state, action);
    }
    case GET_MINI_EXPERT_TREE_FOCUS_SUCCESS: {
      return initMiniExpertTreeData(state, action);
    }
    case GET_MENU_TREE_FOCUS_SUCCESS: {
      return initMenuTreeData(state, action);
    }
    case TREE_DATA_CHANGE: {
      return treeDataChange(state, action);
    }
    case TREE_EXPAND_COLLAPSE_ALL: {
      return expandCollapseAll(state, action);
    }
    case TREE_ON_CRUD_NODE_EXPERT_SUCESS: {
      return onCrudActionExpert(state, action);
    }
    case TREE_ON_CRUD_NODE_EXPERT_ERROR: {
      return onCrudActionExpert(state, action);
    }
    case TREE_ON_CRUD_NODE_SUCESS: {
      return onCrudAction(state, action);
    }
    case TREE_ON_CRUD_NODE_ERROR: {
      return onCrudAction(state, action);
    }
    case ON_TREE_FOCUS_CHANGE_SUCCESS: {
      return onFocusChange(state, action);
    }
    case TREE_INFO_LOADED: {
      return infoLoaded(state, action);
    }
    case SHOULD_UPDATE_TREE:
      return shouldUpdate(state, true, action);
    case SHOULD_NOT_UPDATE_GALAXY:
      return shouldUpdate(state, false, action);
    default: {
      return state;
    }
  }
}

/**
 * La taille des rows dépend de la taille du contenu le plus grand.
 * Cette taile ne peut être calculée que à partir des éléments HTML affiché.
 * On ne peut donc calculer cette taille que APRES avoir render une première fois l'arbre.
 *
 * Une fois la taille calculé si elle n'est pas la même que précédemment on re-render l'arbre.
 *
 */
function infoLoaded(
  state: TreeReducerState,
  action: Action<{
    sjmoCode: string;
    panelId: string;
    selectedTree: number;
  }>
): TreeReducerState {
  const { sjmoCode, panelId } = action.payload;
  return produce(state, draft => {
    if (state[sjmoCode] && state[sjmoCode][panelId]) {
      // Partir de la racine de l'arbre au lieux de document
      let spansTitle = document.getElementsByClassName("spanRowTitle");
      const spansTitleTable: Element[] = [].slice.call(spansTitle);

      const maxSpanHeight = Math.max.apply(
        Math,
        spansTitleTable.map(span => {
          return span.children[0] ? span.children[0].clientHeight : 0;
        })
      );

      // 30 est l'écart placé par la librairie entre la taille du titre et la taille de la row
      const neededRowHeight = maxSpanHeight > 0 ? maxSpanHeight + 30 : 50;
      draft[sjmoCode][panelId].rowHeight = neededRowHeight;
    }
  });
}

/**
 * Methode qui enrichie chaque node avec le sjmoCode et le panelId de l'arbre auquel elle appatient.
 * On fait cela afin d'avoir accès à ces informations au sein du treeContentRenderer.
 * Ces informations sont utiles pour le systme qui recalcule automatiquement la hauteur des nodes
 * en fonction de leur contenu
 *
 * @param {string} sjmoCode
 * @param {number} panelId
 * @param {FlatTreeNode[]} flatTreeData
 * @returns
 */
function contextualise(sjmoCode: string, panelId: string, flatTreeData: FlatTreeNode[]) {
  return flatTreeData.map(node => {
    node.options.sjmoCode = sjmoCode;
    node.options.panelId = panelId;
    return node;
  });
}

function initTreeFocuses(
  state: TreeReducerState,
  action: Action<{
    sjmoCode: string;
    panelId: string;
    treeFocuses: TreeFocus[];
    selectedTree: string;
  }>
): TreeReducerState {
  return produce(state, draft => {
    const { sjmoCode, panelId, treeFocuses, selectedTree } = action.payload;

    if (!draft[sjmoCode]) {
      draft[sjmoCode] = {};
    }

    let focusObject: { [id: number]: TreeFocus } = {};
    treeFocuses.forEach(focus => {
      focusObject[focus.focusId] = focus;
    });

    draft[sjmoCode][panelId] = {
      treeFocuses: focusObject,
      treeDatas: {},
      selectedTree,
      rowHeight: 70
    };
  });
}

function getTreeData(
  state: TreeReducerState,
  action: Action<{
    sjmoCode: string;
    panelId: string;
    treeId: number;
    rootId: number;
    rootType: string;
    flatTreeData: FlatTreeNode[];
  }>
): TreeReducerState {
  return produce(state, draft => {
    const { sjmoCode, panelId, treeId, rootId, rootType, flatTreeData } = action.payload;

    const contextualisedFlatTreeData = contextualise(sjmoCode, panelId, flatTreeData);

    // on récupère le type d'algorithme à appliquer
    const def = draft[sjmoCode][panelId];
    const currentFocus = def.treeFocuses[def.selectedTree];
    const typeAlgo = currentFocus.typeAlgorithm;

    // Est-ce que l'entité contextualisante est présente dans le tree (ie on affiche la racine)
    // Dans ce cas on a une node avec parent==null qui est notre racine
    const trueRootId =
      contextualisedFlatTreeData.findIndex(n => n.parent === null) > -1 ? null : rootId + "";

    // on construit l'arbre en fonction de l'algorithme
    let roughTreeData: RoughTreeData[];
    if (typeAlgo === "SIBLINGS_BY") {
      roughTreeData = dataWithOrderToTree(
        trueRootId,
        0,
        contextualisedFlatTreeData as any
      ) as RoughTreeData[];
    } else {
      roughTreeData = getTreeFromFlatData(
        contextualisedFlatTreeData,
        node => node.nature + node.id,
        node => (node.parent && node.parentNature ? node.parentNature + node.parent : undefined),
        rootType + rootId
      );
    }

    def.treeDatas[treeId] = roughTreeData;
  });
}

function onFocusChange(
  state: TreeReducerState,
  action: Action<{
    sjmoCode: string;
    panelId: string;
    treeId: string;
  }>
): TreeReducerState {
  const { sjmoCode, panelId, treeId } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][panelId].selectedTree = treeId;
  });
}

function addExpertColorProperties(treeData: FlatTreeNode[]): FlatTreeNode[] {
  const flatWithColor = treeData.map(node => {
    let color;

    switch (node.nature) {
      case ADMIN_EXPERT_TREE_COMPONENT:
        color =
          node.options && node.options[ADMIN_EXPERT_TREE_IS_HEADER] === true
            ? "#3273dc"
            : "#209cee";
        break;
      case ADMIN_EXPERT_TREE_CONTAINER:
        color = "#23d160";
        break;
      case ADMIN_EXPERT_TREE_DATATABLE:
        color = "#00d1b2";
        break;
      default:
        break;
    }
    node.options.handleColor = color;
    return node;
  });
  return flatWithColor;
}

/**
 * Initialisation de l'arbre admin expert.
 *
 * Pour ce cas particulier on définit manuellement :
 *  - sjmoCode = EXPERT
 *  - panelId = 0
 *
 * @param {TreeReducerState} state
 * @param {Action<FlatTreeNode[]>} action
 * @returns {TreeReducerState}
 */
function initExpertTreeData(
  state: TreeReducerState,
  action: Action<{ treeData: FlatTreeNode[]; treeId: string }>
): TreeReducerState {
  return initExpertsTreeData(state, action, ADMIN_EXPERT_TREE_MODULE, ADMIN_EXPERT_TREE_PANEL_ID);
}

/**
 * Initialisation de l'arbre admin expert.
 *
 * Pour ce cas particulier on définit manuellement :
 *  - sjmoCode = EXPERT
 *  - panelId = 0
 *
 * @param {TreeReducerState} state
 * @param {Action<FlatTreeNode[]>} action
 * @returns {TreeReducerState}
 */
function initMiniExpertTreeData(
  state: TreeReducerState,
  action: Action<{ treeData: FlatTreeNode[]; treeId: string }>
): TreeReducerState {
  return initExpertsTreeData(
    state,
    action,
    ADMIN_MINI_EXPERT_TREE_MODULE,
    ADMIN_MINI_EXPERT_TREE_PANEL_ID
  );
}

/**
 * Initialisation de l'arbre admin menu.
 *
 * Pour ce cas particulier on définit manuellement :
 *  - sjmoCode = MENU
 *  - panelId = 0
 *
 * @param {TreeReducerState} state
 * @param {Action<FlatTreeNode[]>} action
 * @returns {TreeReducerState}
 */
function initMenuTreeData(
  state: TreeReducerState,
  action: Action<{ treeData: FlatTreeNode[]; treeId: string }>
): TreeReducerState {
  return initExpertsTreeData(state, action, ADMIN_MENU_TREE_MODULE, ADMIN_MENU_TREE_PANEL_ID);
}

/**
 * Initialisation de l'arbre admin expert et admin mini expert.
 *
 * @param {TreeReducerState} state
 * @param {Action<FlatTreeNode[]>} action
 * @returns {TreeReducerState}
 */
function initExpertsTreeData(
  state: TreeReducerState,
  action: Action<{ treeData: FlatTreeNode[]; treeId: string }>,
  sjmoCode: string,
  panelId: string
): TreeReducerState {
  return produce(state, draft => {
    const flatWithColor = addExpertColorProperties(action.payload.treeData);

    const contextualisedFlatTreeData = contextualise(sjmoCode, panelId, flatWithColor);

    const expertTreeData = getTreeFromFlatData(
      contextualisedFlatTreeData,
      node => node.nature + node.id,
      // Seul un conteneur peut être parent
      node => (node.parent && node.parentNature ? node.parentNature + node.parent : undefined),
      undefined
    );

    if (!draft[sjmoCode]) {
      draft[sjmoCode] = {};
    }

    draft[sjmoCode] = {
      [panelId]: {
        treeFocuses: {},
        treeDatas: { [action.payload.treeId]: expertTreeData },
        selectedTree: action.payload.treeId,
        rowHeight: 70
      }
    };
  });
}

function treeDataChange(
  state: TreeReducerState,
  action: Action<{ treeData: RoughTreeData[]; sjmoCode: string; panelId: string }>
): TreeReducerState {
  const { sjmoCode, panelId, treeData } = action.payload;
  if (!state[sjmoCode]) return initialState;
  return produce(state, draft => {
    draft[sjmoCode][panelId].treeDatas[state[sjmoCode][panelId].selectedTree] = treeData;
  });
}

function expandCollapseAll(
  state: TreeReducerState,
  action: Action<{ sjmoCode: string; panelId: string; expanded: boolean }>
): TreeReducerState {
  return produce(state, draft => {
    const { sjmoCode, panelId, expanded } = action.payload;
    visitor(
      draft[sjmoCode][panelId].treeDatas[state[sjmoCode][panelId].selectedTree],
      current => {
        current.expanded = expanded;
      },
      current => {
        return current.children;
      }
    );
  });
}

/**
 * Cette méthode doit :
 *  - Enrichir les nodes qui ont bougée (provenant du serveur) avec la propriété expanded
 *    qu'elles avaient sur le state.
 *  - Remplacer les nodes existantes qui ont bougées
 *
 * @param {TreeReducerState} state
 * @param {Action<{ flatTreeData: FlatTreeNode[]; sjmoCode: string; panelId: string }>} action
 * @returns {TreeReducerState}
 */
function onCrudAction(
  state: TreeReducerState,
  action: Action<{
    flatTreeData: FlatTreeNode[];
    sjmoCode: string;
    rootId: number;
    rootType: string;
    panelId: string;
  }>
): TreeReducerState {
  let { flatTreeData, sjmoCode, panelId, rootId, rootType } = action.payload;

  const flatWithColor = addExpertColorProperties(flatTreeData);
  const treeId = state[sjmoCode][panelId].selectedTree;

  return getTreeData(state, {
    type: "",
    payload: {
      sjmoCode,
      panelId,
      treeId: treeId as any,
      rootId,
      flatTreeData: flatWithColor,
      rootType
    }
  });
}

/**
 * Pour expert uniquement.
 * Cette méthode doit :
 *  - Enrichir les nodes qui ont bougée (provenant du serveur) avec la propriété expanded
 *    qu'elles avaient sur le state.
 *  - Remplacer les nodes existantes qui ont bougées
 *
 * @param {TreeReducerState} state
 * @param {Action<{ flatTreeData: FlatTreeNode[]; sjmoCode: string; panelId: string }>} action
 * @returns {TreeReducerState}
 */
function onCrudActionExpert(
  state: TreeReducerState,
  action: Action<{
    flatTreeData: FlatTreeNode[];
    sjmoCode: string;
    rootId: number;
    rootType: string;
    panelId: string;
  }>
): TreeReducerState {
  let { flatTreeData, sjmoCode, panelId, rootId, rootType } = action.payload;

  const flatWithColor = addExpertColorProperties(flatTreeData);

  const contextualisedFlatTreeData = contextualise(sjmoCode, panelId, flatWithColor);

  return produce(state, draft => {
    // Applatissement l'arbre du state pour pouvoir comparer avec les données entrante
    const oldFlatTreeData: FlatTreeNode[] = getFlatDataFromTree({
      treeData: state[sjmoCode][panelId].treeDatas[state[sjmoCode][panelId].selectedTree],
      ignoreCollapsed: false
    });

    contextualisedFlatTreeData.map(node => {
      // Id est unique mais pas forcément présent selon ce qu'il c'est passé dans le package
      const oldNodes = oldFlatTreeData.filter(currentNode => currentNode.id === node.id);
      if (oldNodes && oldNodes[0]) {
        node.expanded = oldNodes[0].expanded;
      }
    });

    const treeData = getTreeFromFlatData(
      flatWithColor,
      node => node.nature + node.id,
      node => (node.parent && node.parentNature ? node.parentNature + node.parent : undefined),
      rootType && rootId ? rootType + rootId : undefined
    );
    draft[sjmoCode][panelId].treeDatas[state[sjmoCode][panelId].selectedTree] = treeData;
  });
}

function shouldUpdate(
  state: TreeReducerState,
  update: boolean,
  action: Action<{
    flatTreeData: FlatTreeNode[];
    sjmoCode: string;
    rootId: number;
    rootType: string;
    panelId: string;
  }>
): TreeReducerState {
  const { sjmoCode, panelId } = action.payload;
  return produce(state, draft => {
    if (panelId && state[sjmoCode] && state[sjmoCode][panelId]) {
      draft[sjmoCode][panelId].shouldUpdate = update;
    } else if (state[sjmoCode]) {
      Object.keys(state[sjmoCode]).forEach(current => {
        draft[sjmoCode][current].shouldUpdate = update;
      });
    }
  });
}
