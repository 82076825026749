import React, { FC, lazy } from "react";
import { Helmet } from "react-helmet-async";

import Modal from "composants/Modal/Modal";
import ContextInfo from "composants/rightClick/ContextInfo";

import { removeLovParamsFromURL } from "utils/navigation.utils";

import SatellitesData from "containers/satellites/SatellitesData";
import { t } from "utils/i18n";

import { Trans } from "react-i18next";
import { SuperUserLink } from "composants/userSettings/SuperUser";
import { AppGalaxyInfo } from "types/Galaxy";
import { ValidateFlux } from "composants/kanban/ValidateFlux";

import { ProcessusDefinitionNature } from "types/Processus";
import { memoize } from "lodash-es";
import { ON_CLOSE_CREATOR_CALLBACK } from "constant/creator";
import { ON_CLOSE_MINIEXPERT_CALLBACK } from "constant/miniexpert";
import {
  Route,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  Outlet
} from "react-router-dom";

import Expert, { ExpertRedirect, ExpertNotFound } from "containers/expert/Expert";

const Galaxie = lazy(() => import("containers/galaxy/Galaxie"));
const Creator = lazy(() => import("containers/creator/Creator"));
const KanbanPage = lazy(() => import("pages/KanbanPage"));
const SchedulerPage = lazy(() => import("pages/SchedulerPage"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));

const Lov = lazy(() => import("composants/lov/Lov"));
const MiniExpert = lazy(() => import("containers/miniexpert/MiniExpert"));
const Mail = lazy(() => import("composants/email/Mail"));
const ParamAvanceDialog = lazy(() => import("composants/processus/ParamAvanceDialog"));

const GalaxyCompo: FC<{ code: string }> = props => {
  const params = useParams<{ code: string; id: any; focus: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <React.Suspense fallback={null}>
        <Galaxie
          sjmoCode={props.code}
          params={params}
          location={location}
          navigate={navigate}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </React.Suspense>
      <Outlet />
    </>
  );
};

const RenderHome: FC = props => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <React.Suspense fallback={null}>
      <Helmet titleTemplate="%s">
        <title>{t("commun_accueil")}</title>
      </Helmet>
      <Galaxie
        sjmoCode="HOME"
        params={params}
        location={location}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    </React.Suspense>
  );
};
export const MiniExpertCompo: FC = () => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  // on récupère le paramètre tableName
  const URLParams = new URLSearchParams(location.search);
  const tableName = URLParams.get("miniExpertTableName");
  const entityId = URLParams.get("miniExpertEntityId") as string;
  const sjmoCode = URLParams.get("miniExpertSjmoCode");
  const ctrlKey = URLParams.get("miniExpertArboCtrlKey");

  // on affiche le creator que si l'on a le paramètre tableName de présent
  if (tableName) {
    // si on a une origine, on navigue vers l'origine à la fermeture
    // sinon, on retourne en arrière avec `navigate(-1)`

    return (
      <Modal
        show
        onClose={() => {
          try {
            // on execute le callback des trees s'il y en as (cas d'un mini expart dans une galaxie)
            const event = new CustomEvent(ctrlKey + "--satellite");
            window.dispatchEvent(event);
            // On execute le callback dans le cas d'un mini expert ans une modale
            window[ON_CLOSE_MINIEXPERT_CALLBACK] && (window as any)[ON_CLOSE_MINIEXPERT_CALLBACK]();
          } catch (e) {
            console.log(e);
          } finally {
            // on vide le callback pour éviter de polluer l'object global
            // avec des fonctions. Cela permet de faire passer le garbage collector
            // pour la fonction callback.
            (window as any)[ON_CLOSE_MINIEXPERT_CALLBACK] = undefined;
          }
          const urlParams = new URLSearchParams(location.search);
          urlParams.delete("miniExpertTableName");
          urlParams.delete("miniExpertEntityId");
          urlParams.delete("miniExpertSjmoCode");
          setSearchParams(urlParams);
        }}
        title="Modification"
        hideFooter={true}
      >
        <React.Suspense fallback={null}>
          <MiniExpert
            sjmoCode={sjmoCode ? sjmoCode : ""}
            tableName={tableName}
            currentMainEntityId={entityId}
          />
        </React.Suspense>
      </Modal>
    );
  } else {
    return null;
  }
};

const creatorContextualValues = memoize(search => {
  const urlParams = new URLSearchParams(search);
  let contextualValues: { [key: string]: any } | null = {};
  const managedProperties = [
    "creatorTableName",
    "creatorContextTable",
    "creatorContextId",
    "creatorSjmoCode",
    "navigateOnClose",
    "focusId",
    // on exclut les query params des autres modales également
    // mini-expert
    "miniExpertTableName",
    "miniExpertEntityId",
    "miniExpertSjmoCode",
    // information clic droit
    "contextInfo",
    "contextColumnName",
    "origineTableName",
    "origineTableId",
    "origineId"
  ];

  urlParams.forEach((value, key) => {
    if (managedProperties.indexOf(key) < 0 && contextualValues != null) {
      contextualValues[key] = decodeURIComponent(value);
    }
  });
  if (Object.entries(contextualValues).length === 0) {
    contextualValues = null;
  }
  return contextualValues;
});

export const CreatorCompo: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // on récupère le paramètre tableName
  const tableName = searchParams.get("creatorTableName");
  const contextTable = searchParams.get("creatorContextTable") as string;
  const contextIdTemp = searchParams.get("creatorContextId") as string;
  const sjmoCode = searchParams.get("creatorSjmoCode");
  const navigateOnClose = searchParams.get("navigateOnClose")
    ? searchParams.get("navigateOnClose") === "EXIT"
      ? "EXIT"
      : searchParams.get("navigateOnClose") === "false"
      ? false
      : true
    : true;
  const focusId = searchParams.get("focusId");
  const params = useParams<{ code: string; id: any }>();

  const contextualValues = creatorContextualValues(searchParams);
  const galaxyMainEntityId = params.id ?? undefined;

  const contextId =
    contextIdTemp !== undefined && contextIdTemp !== null ? contextIdTemp : undefined;

  const title = (
    <>
      <Trans key={"commun_creation"}>Création</Trans>
      <SuperUserLink url={`/admin/integrateur/creator/${tableName}`} className="ml-6" />
    </>
  );

  function onClose() {
    const urlParams = searchParams;
    urlParams.delete("creatorTableName");
    urlParams.delete("creatorContextTable");
    urlParams.delete("creatorContextId");
    urlParams.delete("creatorSjmoCode");
    urlParams.delete("navigateOnClose");

    try {
      // on execute le callback
      window[ON_CLOSE_CREATOR_CALLBACK] && (window as any)[ON_CLOSE_CREATOR_CALLBACK]();
    } catch (e) {
      console.log(e);
    } finally {
      // on vide le callback pour éviter de polluer l'object global
      // avec des fonctions. Cela permet de faire passer le garbage collector
      // pour la fonction callback.
      (window as any)[ON_CLOSE_CREATOR_CALLBACK] = undefined;
    }

    navigate(location.pathname, {
      state: {
        search: urlParams.toString()
      }
    });
  }

  // on affiche le creator que si l'on a le paramètre tableName de présent
  if (tableName) {
    // si on a une origine, on navigue vers l'origine à la fermeture
    // sinon, on retourne en arrière avec `navigate(-1)`
    return (
      <React.Suspense fallback={null}>
        <Creator
          sjmoCode={sjmoCode ? sjmoCode : ""}
          galaxyMainEntityId={galaxyMainEntityId}
          mainTableName={tableName}
          contextTableName={contextTable}
          contextId={contextId}
          navigateOnClose={navigateOnClose}
          onClose={onClose}
          contextualValues={contextualValues}
          focusId={focusId}
          title={title}
        />
      </React.Suspense>
    );
  } else {
    return null;
  }
};

export const FluxModalCompo: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   * exemple d'url :
   * ?kanbanId=8DF7029F012178F0E053560110ACA9DB&template=MODAL_GCO_DEVIS&entityId=D19-00146
   * ?processId=8DF47970D9E34D2FE053560110AC4705&template=MODAL_GCO_DEVIS&entityId=D19-00146
   */
  const kanbanId = searchParams.get("kanbanId") as string;
  const processId = searchParams.get("processId") as string;
  const template = searchParams.get("template") as string;
  const entityId = searchParams.get("entityId") as string;

  const params = useParams<{ code: string; id: any; focus: string }>();

  if ((kanbanId || processId) && template && entityId && params.code) {
    const title = (
      <>
        <Trans key={"commun_validation"}>Validation</Trans>
      </>
    );

    const onClose = () => {
      setSearchParams(new URLSearchParams());
    };

    return (
      <Modal show onClose={onClose} title={title} hideFooter={true} height="90vh" width="90vw">
        <React.Suspense fallback={null}>
          <ValidateFlux
            kanbanId={kanbanId}
            processId={processId}
            template={template}
            sjmoCode={params.code}
            entityId={entityId}
          />
        </React.Suspense>
      </Modal>
    );
  } else {
    return null;
  }
};

export const MailCompo: FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get("mail") === "true") {
    return (
      <React.Suspense fallback={null}>
        <Mail location={location} setSearchParams={setSearchParams} />;
      </React.Suspense>
    );
  } else {
    return null;
  }
};

export const ContextInfoCompo: FC = () => {
  const [params, setSearchParams] = useSearchParams();

  function deleteSearchContextInfo() {
    params.delete("contextInfo");
    params.delete("contextColumnName");
    params.delete("origineTableName");
    params.delete("origineTableId");
    params.delete("origineId");

    setSearchParams(params);
  }

  if (params.get("contextInfo") === "true") {
    const contextColumnName = params.get("contextColumnName");
    const contextTableName = params.get("origineTableName");
    const origineTableId = params.get("origineTableId");
    const contextTableId = params.get("origineId");
    return (
      <Modal
        show
        onClose={deleteSearchContextInfo}
        onValidate={deleteSearchContextInfo}
        title="Informations"
        minWidth="30vw"
        minHeight="30vh"
        hideFooter={true}
      >
        <React.Suspense fallback={null}>
          <ContextInfo
            contextColumnName={contextColumnName}
            contextTableName={contextTableName}
            origineTableId={origineTableId}
            contextTableId={contextTableId}
          />
        </React.Suspense>
      </Modal>
    );
  } else {
    return null;
  }
};

export const ProcessusAvanceCompo: FC = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.get("process")) {
    const search = urlParams;
    const compositeId = search.get("process") as string;
    const navigationUrl = search.get("navigationUrl");
    const type = search.get("type") as ProcessusDefinitionNature;
    const sjmoCode = search.get("sjmoCode") as string;
    const delay = search.get("delay") as string;
    const label = search.get("processLabel") as string;
    const editionType = search.get("editionType") as "rapide" | "apercu" | undefined;
    const forcedForAll = (search.get("forAll") as string) === "true";
    const refresh = (location.state as any).refresh as string;
    const selected = (location.state as any).selected;
    const needEntity = (location.state as any).needEntity;
    const isOneByOne = (location.state as any).isOneByOne;

    const callback = window[refresh] as () => void;

    return (
      <React.Suspense fallback={null}>
        <div>
          <ParamAvanceDialog
            compositeId={compositeId}
            navigationUrl={navigationUrl}
            type={type}
            sjmoCode={sjmoCode}
            delay={delay}
            label={label}
            editionType={editionType}
            forcedForAll={forcedForAll}
            callback={() => {
              if (callback && typeof callback === "function") {
                callback();
                delete window[refresh];
              }
            }}
            selected={selected}
            needEntity={needEntity}
            isOneByOne={isOneByOne}
          />
        </div>
      </React.Suspense>
    );
  } else {
    return null;
  }
};

export const SatelliteCompo: FC = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  if (urlParams.get("currentTab")) {
    const contextId = (urlParams.get("satelliteContextId") as string) || undefined;
    const ctrlKey = (urlParams.get("ctrlKey") as string) || undefined;
    const query = (urlParams.get("query") as string) || undefined;
    const columns = (urlParams.get("columns") as string) || undefined;

    return (
      <React.Suspense fallback={null}>
        <SatellitesData
          currentTab={urlParams.get("currentTab") as string}
          tableName={urlParams.get("satelliteTableName") as string}
          initContextId={contextId}
          sjmoCode={urlParams.get("satelliteSjmoCode") as string}
          ctrlKey={ctrlKey}
          query={query}
          columns={columns ? columns.split(",") : undefined}
        />
      </React.Suspense>
    );
  }
  return null;
};

export const LovCompo: FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // code module + target + selectionUnique permet de savoir d'ou l'on vient :
  // -> lors de la validation, si jamais le path code + target et sélection unique n'est pas bon,
  // on ignore l'ajout.

  if (searchParams.get("lovTargetTableName")) {
    return (
      <React.Suspense fallback={null}>
        <Lov
          // chemin utilisé pour la validation
          sjmoCode={searchParams.get("lovSjmoCode") as string}
          targetTableName={searchParams.get("lovTargetTableName") as string}
          targetCtrlKey={searchParams.get("lovTargetCtrlKey") as string}
          targetId={searchParams.get("lovTargetId") as string}
          onClose={() => {
            const urlParams = removeLovParamsFromURL(location.search);
            setSearchParams(urlParams);
          }}
          // permet de savoir si on est sur une GS ou DT
          lovType={searchParams.get("lovType") as any}
          // information pour charger la LOV
          column={searchParams.get("lovColumn") as string}
          tableName={searchParams.get("lovTableName") as string}
          contextTableName={searchParams.get("contextTableName") as string}
          contextId={searchParams.get("contextId") as string}
          syjLovId={searchParams.get("lovId") as string}
        />
      </React.Suspense>
    );
  }

  return null;
};

export const KanbanPageComponent: FC = () => {
  const location = useLocation();
  const sjmoCode = location.pathname.split("/")[2];
  //TODO remettre le label
  return (
    <React.Suspense fallback={null}>
      <Helmet titleTemplate="%s">
        <title>{"label" || "Kanban"}</title>
      </Helmet>
      <KanbanPage sjmoCode={sjmoCode} location={location} />
    </React.Suspense>
  );
};

export const SchedulerComponent: FC<{ type: "TIMELINE" | "CALENDAR"; titre: string }> = props => {
  const location = useLocation();
  const sjmoCode = location.pathname.split("/")[2];
  return (
    <React.Suspense fallback={null}>
      <Helmet titleTemplate="%s">
        <title>{props.titre}</title>
      </Helmet>
      <SchedulerPage sjmoCode={sjmoCode} type={props.type} />
    </React.Suspense>
  );
};

export const AppRoute: FC<{ galaxies: Record<string, AppGalaxyInfo> }> = ({ galaxies }) => {
  const keys = Object.keys(galaxies);

  return (
    <>
      {keys.map(key => {
        const currentPage = galaxies[key];
        switch (currentPage.type) {
          case "GALAXY":
            return (
              <Route
                key={currentPage.code + "_id"}
                path={`/page/${currentPage.code}`}
                element={<GalaxyCompo key={currentPage.code} code={currentPage.code} />}
              >
                <Route path=":id">
                  <Route
                    path="expert"
                    element={<ExpertRedirect sjmoCode={currentPage.code} />}
                    errorElement={<ExpertNotFound />}
                  >
                    <Route
                      path=":expertId"
                      element={<Expert sjmoCode={currentPage.code} height="calc(70vh - 40px)" />}
                    />
                  </Route>
                </Route>
              </Route>
            );

          case "HOME":
            return (
              <Route
                key={currentPage.code + "_ID"}
                path={`/page/${currentPage.code}`}
                element={<RenderHome key={currentPage.code} />}
              />
            );

          case "KANBAN":
            return (
              <Route
                key={currentPage.code + "_id"}
                path={`/page/${currentPage.code}`}
                element={<KanbanPageComponent key={currentPage.code} />}
              />
            );

          case "UNIVERS":
            return (
              <Route
                key={currentPage.code + "_id"}
                path={`/page/${currentPage.code}`}
                element={<GalaxyCompo key={currentPage.code} code={currentPage.code} />}
              >
                <Route path=":id" />
              </Route>
            );

          case "TIMELINE":
            return (
              <Route
                key={currentPage.code + "_id"}
                path={`/page/${currentPage.code}`}
                element={
                  <SchedulerComponent
                    key={currentPage.code}
                    type="TIMELINE"
                    titre={currentPage.label}
                  />
                }
              >
                <Route path=":id" />
              </Route>
            );

          case "CALENDAR":
            return (
              <Route
                key={currentPage.code + "_id"}
                path={`/page/${currentPage.code}`}
                element={
                  <SchedulerComponent
                    key={currentPage.code}
                    type="CALENDAR"
                    titre={currentPage.label}
                  />
                }
              >
                <Route path=":id" />
              </Route>
            );
          default:
            return null;
        }
      })}
      <Route path="/process" element={<ProcessusAvanceCompo />} />
      <Route path="/" element={<RenderHome />} />
      <Route
        path="*"
        element={
          <React.Suspense fallback={null}>
            <PageNotFound />
          </React.Suspense>
        }
      />
    </>
  );
};
